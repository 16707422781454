import axiosRequest from "api";
import { payloadHelper } from "helpers/payload-helper";
import { ApiPaginationResponse } from "types/api";
import { AnnouncementType } from "types/retrieve";

export const retrieveApi = {
  async retrieveAnnouncement(page: number, size: number) {
    const { data } = await axiosRequest.post<
      ApiPaginationResponse<AnnouncementType[]>
    >(
      "/",
      payloadHelper("Announcement", null, {
        page,
        size
      })
    );
    return data;
  }
};
