import { useEffect, useState } from "react";

function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height
  };
}
type FuncType = (...args: any[]) => void;
const debounce = (func: FuncType, delay: number): FuncType => {
  let timerId: ReturnType<typeof setTimeout> | null;

  return (...args: any[]) => {
    if (timerId) {
      clearTimeout(timerId);
    }

    timerId = setTimeout(() => {
      func.apply(this, args);
      timerId = null;
    }, delay);
  };
};
export default function useWindowSize() {
  const [windowDimensions, setWindowDimensions] = useState(
    getWindowDimensions()
  );
  const debouncedSaveData = debounce(() => {
    setWindowDimensions(getWindowDimensions());
  }, 200);
  useEffect(() => {
    function handleResize() {
      debouncedSaveData();
    }
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return windowDimensions;
}
