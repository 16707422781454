import { createSlice } from "@reduxjs/toolkit";
import { ApplicationRootState } from "types";
import { RetrieveStateType } from "types/retrieve";

import { extraReducers } from "./retrieve.extraReducers";

export const initialState: RetrieveStateType = {
  isAnnouncementLoading: false,
  announcements: [],
  totalAnnouncements: 0
};
const retrieveSlice = createSlice({
  name: "retrieve",
  initialState,
  reducers: {
    //
  },
  extraReducers
});

export const selectAnnouncements = (state: ApplicationRootState) =>
  state.retrieve.announcements;

export const selectIsAnnouncementLoading = (state: ApplicationRootState) =>
  state.retrieve.isAnnouncementLoading;

export const selectTotalAnnouncements = (state: ApplicationRootState) =>
  state.retrieve.totalAnnouncements;

export default retrieveSlice.reducer;
