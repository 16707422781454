import { css } from "@emotion/react";
import { media } from "styles/breakpoints";

export const NotificationCenterStyle = {
  wrapper: css`
    background-color: #101621;
    margin-top: 20px;

    display: flex;
    flex-direction: column;
    gap: 20px;
    ${media.md} {
      gap: 10px;

      padding: 10px;
    }
  `,
  item: css``
};
