import { css } from "@emotion/react";
import { media } from "styles/breakpoints";
import { theme } from "styles/theme";

export const RankStatusStyle = {
  wrapperStatus: css`
    display: grid;
    grid-template-columns: repeat(6, 1fr);
    gap: 16px;

    margin-top: 20px;

    ${media.xxl} {
      grid-template-columns: repeat(5, 1fr);
    }
    ${media.lg} {
      grid-template-columns: repeat(3, 1fr);
      grid-template-rows: repeat(2, 1fr);
    }
    ${media.sm} {
      display: flex;
      flex-direction: column;
    }
  `,
  title: css`
    font-size: 22px;
    font-weight: 600;

    margin-top: 40px;
  `,
  box: css`
    grid-column-start: 1;
    grid-column-end: 3;

    background-color: ${theme.colors.primary[650]};
    border-radius: 10px;

    display: flex;
    justify-content: flex-start;
    align-items: center;

    &:first-of-type {
      padding: 30px 15px;
    }
    &:last-of-type {
      grid-column-start: 3;
      grid-column-end: 6;

      ${media.lg} {
        grid-column-start: 1;
        grid-row-start: 2;
      }

      padding: 35px 10px;
      flex-direction: column;
      justify-content: space-between;
      align-items: flex-start;
    }
  `,
  boxUser: {
    avatar: css`
      height: 110px;
      width: 110px;
      margin-right: 30px;
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    `,
    information: css`
      p {
        color: ${theme.colors.secondary[900]};
        font-size: 14px;
        font-weight: 500;
        margin-bottom: 10px;
      }
    `,
    currentVip: css`
      font-weight: 700;
      font-size: 16px;
      line-height: 1.375rem;

      margin-bottom: 20px;
      span {
        color: ${theme.colors.secondary[900]};
      }
    `,
    percentage: css`
      cursor: pointer;
      &:hover {
        a {
          transition: 0.25s ease;
          border-color: ${theme.colors.secondary[900]};
          color: ${theme.colors.secondary[900]};
        }
      }
      a {
        transition: 0.25s ease;
        border: 1px solid ${theme.colors.white};
        padding: 3px 12px;
        border-radius: 18px;
        color: white;
        text-decoration: none;

        line-height: 1rem;
        font-size: 12px;
      }
    `
  },
  boxVip: {
    header: css`
      font-size: 16px;
      font-weight: 500;
      margin-bottom: 10px;
      span {
        color: ${theme.colors.secondary[900]};
      }
    `,
    progress: css`
      width: 100%;
      .ant-progress {
        &-text {
          display: none;
        }
        &-line {
          margin-bottom: unset;
        }
        &-outer {
          height: 16px !important;

          margin-inline-end: unset !important;
          padding-inline-end: unset !important;
        }
        &-inner {
          background-color: ${theme.colors.neutrals[600]};
          height: 100%;
        }
        &-bg {
          height: 100% !important;
          background: ${theme.colors.gradient[3]};
        }
      }
    `,
    turnover: css`
      width: 100%;

      display: flex;
      align-items: center;
      justify-content: space-between;
    `
  },
  currentTurnover: css`
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    color: white;
  `,
  fullTurnover: css`
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    color: white;
    span {
      color: ${theme.colors.secondary[900]};
    }
  `,

  wrapperBenefit: css``
};
