/** @jsxImportSource @emotion/react */

import { ConfigProvider, Form, Input, InputProps } from "antd";
import { ValidateStatus } from "antd/es/form/FormItem";
import { Control, Controller, FieldValues } from "react-hook-form";

import { InputCustomStyle } from "./index.style";

export interface InputCustomType extends InputProps {
  margin_bottom?: string;
  name?: string;
  control?: Control<FieldValues | any>;
  errors?: string;
  validate_status?: ValidateStatus;
  defaultValue?: string | number;
  width?: string;
  label?: string;
}

const InputCustom = (props: InputCustomType) => {
  const {
    name = "",
    control,
    margin_bottom,
    errors,
    validate_status,
    defaultValue,
    width,
    label
  } = props;

  return (
    <>
      {control ? (
        <Form.Item
          // className="non-reset"
          css={InputCustomStyle.self}
          label={
            label ? (
              <p style={{ fontSize: "16px", color: "white" }}>{label}</p>
            ) : null
          }
          help={errors}
          validateStatus={validate_status}
          style={{
            marginBottom: margin_bottom ? margin_bottom : "20px",
            width: width ? width : "100%"
          }}
          labelCol={{ span: 24 }}
        >
          <Controller
            name={name}
            defaultValue={defaultValue}
            control={control}
            render={({ field: { onChange, value } }) => {
              return (
                <ConfigProvider
                  theme={{
                    token: {
                      colorBgContainer: "#101520",
                      colorBorder: "#3D4668",
                      colorText: "#FFFFFF80"
                    }
                  }}
                >
                  <Input
                    onChange={onChange}
                    value={value}
                    autoComplete="off"
                    {...props}
                  />
                </ConfigProvider>
              );
            }}
          />
        </Form.Item>
      ) : (
        <Input {...props} autoComplete="off" css={InputCustomStyle.self} />
      )}
    </>
  );
};

export default InputCustom;
