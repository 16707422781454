/** @jsxImportSource @emotion/react */

import { ConfigProvider, Form } from "antd";
import { ValidateStatus } from "antd/es/form/FormItem";
import Input, { PasswordProps } from "antd/es/input";
import { Control, Controller, FieldValues } from "react-hook-form";

import { InputPasswordStyle } from "./index.style";

export interface InputPasswordProps extends PasswordProps {
  name?: string;
  control?: Control<FieldValues | any>;
  defaultValue?: any;
  error?: string;
  validate_status?: ValidateStatus;
  margin_bottom?: string;
}

const InputPassword = (props: InputPasswordProps) => {
  const {
    name = "",
    control,
    defaultValue,
    error,
    validate_status,
    margin_bottom
  } = props;

  return (
    <>
      {control ? (
        <Form.Item
          help={error}
          validateStatus={validate_status}
          css={InputPasswordStyle.self}
          style={{ marginBottom: margin_bottom ? margin_bottom : "20px" }}
        >
          <Controller
            name={name}
            control={control}
            render={({
              field: { onChange: _onChange },
              formState: { errors }
            }) => (
              <ConfigProvider
                theme={{
                  token: {
                    colorBgContainer: "#101520",
                    colorBorder: "#3D4668",
                    colorText: "#FFFFFF80"
                  }
                }}
              >
                <Input.Password
                  defaultValue={defaultValue}
                  onChange={_onChange}
                  autoComplete="off"
                  {...props}
                />
              </ConfigProvider>
            )}
          />
        </Form.Item>
      ) : (
        <Input.Password
          css={InputPasswordStyle.self}
          defaultValue={defaultValue}
          autoComplete="off"
          {...props}
        />
      )}
    </>
  );
};

export default InputPassword;
