/** @jsxImportSource @emotion/react */

import { Modal } from "antd";
import moment from "moment";
import { useTranslation } from "react-i18next";
import { OpenDetailsNotificationType } from "types/retrieve";

import { DetailNotificationStyle } from "./style.index";

interface Props {
  onClose: () => void;
  details: OpenDetailsNotificationType;
}
function DetailNotification({ onClose, details }: Props) {
  const { t } = useTranslation(["common", "notification"]);
  const { dataItem, visible } = details;
  return (
    <Modal
      centered
      open={visible}
      onOk={onClose}
      onCancel={onClose}
      footer={null}
      css={DetailNotificationStyle.wrapper}
      title={t("notification:notification")}
    >
      {dataItem && (
        <>
          <div css={DetailNotificationStyle.header}>
            <p
              css={DetailNotificationStyle.title}
              dangerouslySetInnerHTML={{ __html: dataItem.title }}
            />
            <p css={DetailNotificationStyle.date}>
              {moment(dataItem?.start).format("DD/MM/YYYY")} -{" "}
              {moment(dataItem?.end).format("DD/MM/YYYY")}
            </p>
          </div>
          <p
            css={DetailNotificationStyle.content}
            dangerouslySetInnerHTML={{ __html: dataItem?.content }}
          />
        </>
      )}
    </Modal>
  );
}

export default DetailNotification;
