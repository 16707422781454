export enum Device {
  WEB = 1,
  MOBILE = 2,
  ANDROID = 3,
  IOS = 4
}

export enum maxWidthDevice {
  SM = 576,
  MD = 768,
  LG = 992,
  XL = 1280,
  XXL = 1536
}
