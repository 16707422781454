import Img_flag_EN from "assets/images/flag/flag_EN.webp";
import Img_flag_ZH from "assets/images/flag/flag_ZH.webp";
export const dataLanguage = [
  {
    id: "en",
    name: "English",
    img: Img_flag_EN
  },
  {
    id: "zhS",
    name: "繁體中文",
    img: Img_flag_ZH
  }
];
