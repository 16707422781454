import { css } from "@emotion/react";
import ImagePromotionDetailBg from "assets/images/promotion/promotion-detail-bg.png";
import { media } from "styles/breakpoints";
export const PromotionDetailStyle = {
  wrapper: css`
    margin: 0 70px;

    ${media.lg} {
      margin: unset;
    }

    background: url(${ImagePromotionDetailBg}) center top no-repeat;
    min-height: 100vh;
    ${media.sm} {
      background-size: 100%;
    }
  `,
  title: css`
    text-align: center;
    &:first-of-type {
      padding-top: 160px;
      padding-bottom: 5.4px;
      ${media.sm} {
        padding-top: 30px;
        img {
          max-width: 200px;
        }
      }
    }
    &:nth-of-type(2) {
      img {
        max-width: 300px;
      }
    }
  `,
  bottom: {
    index: css``,
    textBox: css`
      position: relative;
      display: flex;
      justify-content: center;
      &:first-of-type {
        margin-bottom: 15px;
        img {
          max-width: 300px;
        }
      }
      &:nth-of-type(2) {
        img {
          max-width: 400px;
        }
      }
    `,
    content: css`
      position: absolute;
      height: 100%;

      display: flex;
      align-items: center;
      justify-content: space-between;
      p {
        color: #ccc;
        font-size: 25px;
        font-style: normal;
        font-weight: 700;
        line-height: 24px;
        ${media.sm} {
          font-size: 18px;
        }
      }
      span {
        color: #f3d790;
        font-size: 25px;
        font-style: normal;
        font-weight: 700;
        line-height: 24px;
        margin: 0 21px 0 13px;
        ${media.sm} {
          font-size: 18px;
        }
      }
    `
  },
  describe: {
    index: css`
      position: relative;
      display: flex;
      justify-content: center;
      ${media.lg} {
        img {
          max-width: 700px;
        }
      }
      ${media.sm} {
        img {
          max-width: 400px;
        }
      }
    `,
    content: css`
      position: absolute;
      margin-top: 55px;
      max-width: 610px;
      ${media.lg} {
        max-height: 630px;
        overflow: scroll;
        height: auto;
        max-width: 500px;
      }
      ${media.sm} {
        max-height: 330px;
        max-width: 300px;
      }
    `,
    title: css`
      color: #f3d790;
      font-size: 22px;
      font-style: normal;
      font-weight: 400;
      line-height: 28px;
      margin-bottom: 13px;
      ${media.sm} {
        margin-bottom: 8px;
        font-size: 16px;
      }
    `,
    paragraph: css`
      color: #fff;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 22px;
      ${media.sm} {
        font-size: 12px;
      }
    `
  }
};
