/** @jsxImportSource @emotion/react */

import { CloseOutlined, SearchOutlined } from "@ant-design/icons";
import { ConfigProvider, Empty, Input } from "antd";
import { ReactComponent as ArrowIcon } from "assets/icons/arrow.svg";
import HelmetCustom from "components/ui/Helmet";
import { ListProviderStyle } from "components/ui/ListProvider/style.index";
import Loading from "components/ui/Loading";
import { listSort } from "constants/list-hard";
import { useAppDispatch, useAppSelector } from "hooks/app-hook";
import { pageSize } from "pages/TransactionPage/colums";
import { useCallback, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useInView } from "react-intersection-observer";
import { useNavigate, useParams } from "react-router-dom";
import {
  selectAllDataSubGame,
  selectDataSubGame,
  selectIsLoading
} from "store/game";
import { getGameList, getSubGameList } from "store/game/game.thunks";
import { theme } from "styles/theme";
import { SubGamePaginationType, SubGameType } from "types/game";

import { filterSubGame } from "../helpers/utilFilterSubGame";
import {
  useCategoryGameDetail,
  useProviderDetail
} from "../hooks/useCategoryDetail";
import { SubGamePageStyle } from "./index.style";
import { SubGameItem } from "./SubGameItem";

const PAGE_SIZE = 48;
interface State {
  search: string;
  idSort: string;
}
const SubGamePage = () => {
  const { categoryName = " ", providerId = "" } = useParams();
  const navigate = useNavigate();

  const dispatch = useAppDispatch();

  const dataSubGame: SubGamePaginationType = useAppSelector(selectDataSubGame);
  const dataAllSubGame = useAppSelector(selectAllDataSubGame);
  const isLoading = useAppSelector(selectIsLoading);

  const categoryGameDetail = useCategoryGameDetail(categoryName);
  const providerDetail = useProviderDetail(providerId);
  const [pageCurrent, setPageCurrent] = useState<number>(1);

  const providerIdRef = useRef<string | null>(null);
  const [dataFilterGame, setDataFilterGame] = useState<SubGameType[]>([]);
  const { t } = useTranslation(["common"]);

  const [state, setState] = useState<State>({
    search: "",
    idSort: "all"
  });

  const { ref, inView } = useInView();

  useEffect(() => {
    if (!providerId || isLoading) return;
    let page = pageCurrent;
    if (providerIdRef.current !== providerId) {
      page = 1;
      setPageCurrent(1);
    }

    if (inView) {
      setPageCurrent((prev) => prev + 1);
    }

    Promise.all([
      dispatch(
        getSubGameList({
          provider: providerId,
          game_type: categoryGameDetail.id,
          page: page,
          size: PAGE_SIZE,
          featured: state.idSort === "all" ? 0 : 1
        })
      ),
      dispatch(getGameList(categoryGameDetail.id)),
      (providerIdRef.current = providerId)
    ]);
  }, [providerId, pageCurrent, inView, state.idSort]);

  useEffect(() => {
    if (!providerId) return;
    if (!categoryGameDetail.id) return;

    dispatch(
      getSubGameList({
        provider: providerId,
        game_type: categoryGameDetail.id,
        size: 500,
        isAllGame: true,
        featured: state.idSort === "all" ? 0 : 1
      })
    );
  }, [providerId, categoryGameDetail, state.idSort]);

  useEffect(() => {
    const listFilter = dataAllSubGame.data.filter((itemFilter: SubGameType) =>
      filterSubGame(itemFilter, state.search)
    );

    if (!listFilter) return setDataFilterGame([]);

    setDataFilterGame(listFilter);
  }, [state.search]);

  const renderListSubGame = useCallback(() => {
    if (isLoading) return <Loading height={500} width={"100%"} center={true} />;

    if (!dataSubGame.data.length)
      return (
        <div css={SubGamePageStyle.notFound}>
          <Empty css={SubGamePageStyle.empty} />
        </div>
      );

    return (
      <div css={SubGamePageStyle.wrapperListSubGame}>
        {dataSubGame.data.map((subGame: SubGameType, index: number) => (
          <SubGameItem
            subGame={subGame}
            key={`${subGame.game_code}_${index}`}
          />
        ))}
      </div>
    );
  }, [dataSubGame]);

  const renderListSubGameFilter = useCallback(() => {
    return (
      <div css={SubGamePageStyle.wrapperListSubGame}>
        {dataFilterGame.map((subGame: SubGameType, index: number) => (
          <SubGameItem
            subGame={subGame}
            key={`${subGame.game_code}_${index}`}
          />
        ))}
      </div>
    );
  }, [dataFilterGame]);

  const onChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setState((prev) => ({
      ...prev,
      search: e.target.value
    }));
  };

  const onChangeFilter = (idSort: string) => {
    setState((prev) => ({
      ...prev,
      search: "",
      idSort
    }));
    setPageCurrent(1);
    setDataFilterGame([]);
  };

  return (
    <div css={SubGamePageStyle.wrapper}>
      <div css={SubGamePageStyle.container}>
        <HelmetCustom title={categoryGameDetail.name} />
        <div css={SubGamePageStyle.heading}>
          <ArrowIcon
            css={SubGamePageStyle.arrow}
            onClick={() => navigate(-1)}
          />{" "}
          <p css={SubGamePageStyle.title}>
            {categoryName.toUpperCase()} / {providerDetail?.provider_name || ""}
          </p>
        </div>

        <div css={SubGamePageStyle.inputControl}>
          <ConfigProvider
            theme={{
              token: {
                colorBgContainer: "#101520",
                colorBorder: "#3D4668",
                colorText: "#FFFF !important",
                colorTextPlaceholder: theme.colors.neutrals[400]
              }
            }}
          >
            <Input
              key={"input-search"}
              onChange={onChange}
              value={state.search}
              placeholder={t("search")}
              suffix={
                !!state.search && (
                  <CloseOutlined
                    onClick={() =>
                      setState((prev) => ({
                        ...prev,
                        search: ""
                      }))
                    }
                  />
                )
              }
              prefix={<SearchOutlined css={SubGamePageStyle.searchIcon} />}
              css={SubGamePageStyle.input}
            />
          </ConfigProvider>
        </div>

        <div css={SubGamePageStyle.blockSort}>
          <div css={ListProviderStyle.gameType.index}>
            <div css={ListProviderStyle.title}>Sort by</div>
            <div css={ListProviderStyle.gameType.list}>
              {listSort.map((item, index) => {
                const isActive = item.id === state.idSort;
                return (
                  <div key={index}>
                    <div
                      onClick={() => onChangeFilter(item.id)}
                      css={SubGamePageStyle.filter(isActive)}
                    >
                      <span>{item.name}</span>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>

        {state.search ? renderListSubGameFilter() : renderListSubGame()}

        {isLoading ? (
          <Loading height={400} width={"100%"} center={true} />
        ) : null}

        {dataSubGame.data.length > 0 &&
          dataSubGame.data.length >= pageSize &&
          dataSubGame.totalPages >= pageCurrent && <div ref={ref}></div>}

        {/* {!state.search && dataSubGame.data.length > 0 && (
          <div css={SubGamePageStyle.boxPagination}>
            <Pagination
              css={SubGamePageStyle.paginationStyle}
              current={pageCurrent}
              pageSize={PAGE_SIZE}
              total={dataSubGame.totalCount}
              onChange={(page: number) => {
                dispatch(resetDataSubGame());
                setPageCurrent(page);
              }}
              showSizeChanger={false}
            />
          </div>
        )} */}
      </div>
    </div>
  );
};

export default SubGamePage;
