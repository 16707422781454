import { css, Theme } from "@emotion/react";
import { media } from "styles/breakpoints";
import { theme } from "styles/theme";

export const SubGamePageStyle = {
  wrapper: () => css`
    width: 100%;
    padding: 62px 64px;

    ${media.md} {
      padding: 0 16px;
    }

    ${media.sm} {
      padding: 0 16px;
    }
  `,

  heading: css`
    margin-bottom: 12px;
    font-size: 20px;
    font-weight: bold;
    ${media.md} {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
  `,

  title: css`
    flex: 1;
    text-align: center;
  `,

  arrow: css`
    display: none;
    fill: #fff;
    ${media.md} {
      display: inline-block;
      transform: rotate(180deg);
    }
  `,

  empty: css`
    height: 700px;
  `,

  filter: (isActive: boolean) => css`
    cursor: pointer;
    user-select: none;
    display: flex;
    align-items: center;
    gap: 5px;

    border: 1px solid ${isActive ? theme.colors.secondary[900] : "transparent"};

    padding: 4px 16px;
    border-radius: 20px;
    background: ${isActive
      ? "rgba(61, 70, 104, 0.1)"
      : "rgba(61, 70, 104, 0.3)"};
    transition: 0.25s;
    &:hover {
      background: rgba(61, 70, 104, 0.6);
      transition: 0.25s;
    }
    span {
      color: ${isActive ? theme.colors.secondary[900] : "white"};
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 22px;
      text-transform: uppercase;
    }
  `,

  container: () => css`
    display: flex;
    flex-direction: column;
    width: 100%;
  `,

  notFound: () => css`
    height: 100% !important;
    justify-content: center !important;
    display: flex !important;
    flex-direction: column !important;
  `,

  wrapperListSubGame: () => css`
    gap: 16px;
    padding: 0 32px;
    display: grid;
    grid-template-columns: repeat(6, 1fr);

    ${media.md} {
      grid-template-columns: repeat(4, 1fr);
      gap: 8px;
      padding: 0 16px;
    }

    ${media.sm} {
      grid-template-columns: repeat(2, 1fr);
      padding: 0 16px;
      gap: 8px;
    }
  `,

  boxPagination: () => css`
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
  `,

  paginationStyle: ({ colors }: Theme) => css`
    .ant-pagination-next button {
      color: ${colors.primary[300]} !important;
      &:hover {
        border-color: ${colors.primary[300]} !important;
        background-color: ${colors.white} !important;
      }
    }
    .ant-pagination-prev button {
      color: ${colors.primary[300]} !important;
      &:hover {
        border-color: ${colors.primary[300]} !important;
        background-color: ${colors.white} !important;
      }
    }
    .ant-pagination-disabled button:disabled {
      color: ${colors.neutrals[50]} !important;
      &:hover {
        border-color: transparent !important;
        background-color: transparent !important;
      }
    }
    .ant-pagination-item {
      border-color: white;
      a {
        color: white;
      }
    }
    .ant-pagination-item-active {
      border-color: ${colors.primary[300]} !important;
    }
    .ant-pagination-item:hover {
      border-color: ${colors.primary[300]} !important;
      background-color: ${colors.white} !important;
    }
    .ant-pagination-item:hover a {
      color: ${colors.primary[300]} !important;
    }
    .ant-pagination-item-active a {
      color: ${colors.primary[300]} !important;
    }
  `,
  inputControl: css`
    margin: 24px;
    align-items: center;
    justify-content: center;
    display: flex;
    width: 40%;

    ${media.sm} {
      padding: 0;
      margin: 8px 0;
      padding: 0 12px;
      width: 100%;
    }
  `,
  input: () => css`
    border-radius: 8px;
    line-height: 1.8;
    padding: 8px 16px;
  `,
  searchIcon: css`
    margin-right: 8px;
  `,
  blockSort: css`
    margin: 24px 32px;
    background-color: rgba(16, 21, 32, 0.5);
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    gap: 15px;
    ${media.md} {
      padding: 16px;
    }
    ${media.sm} {
      padding: 0;
      margin: 0 24px;
      margin-bottom: 12px;
    }
  `
};
