import axiosRequest from "api";
import { payloadHelper } from "helpers/payload-helper";
import { ApiResponse } from "types/api";

import {
  ListBankResponseType,
  ListPlayerBankResponseType,
  PlayerBankFormType
} from "./type";

export const BankApi = {
  getListBank: (username: string) =>
    axiosRequest.post<ListBankResponseType>(
      "",
      payloadHelper("GetBankList", username, { username })
    ),
  getListPlayerBank: (username: string) =>
    axiosRequest.post<ListPlayerBankResponseType>(
      "",
      payloadHelper("GetMemberBank", username, { username })
    ),
  addPlayerBank: (username: string, payload: PlayerBankFormType) =>
    axiosRequest.post<ApiResponse>(
      "",
      payloadHelper("AddMemberBank", username, payload)
    )
};
