import axiosRequest from "api";
import { Device } from "enums/device";
import { payloadHelper } from "helpers/payload-helper";

import {
  CategoryDetailsResponseType,
  loginGameResType,
  loginGameType,
  SubGamePayLoad,
  SubGameResponse
} from "./type";

export const GameApi = {
  loginGame: (typename: string, payload: loginGameType, isMobile?: boolean) =>
    axiosRequest.post<loginGameResType>(
      "",
      payloadHelper(
        "LoginGame",
        typename,
        payload,
        undefined,
        isMobile ? Device.MOBILE : Device.WEB
      ),
      {
        cancelToken: payload.source?.token
      }
    ),
  getSubGame: (payload: SubGamePayLoad) =>
    axiosRequest.post<SubGameResponse>(
      "",
      payloadHelper("GetSubGameList", "", payload)
    ),

  getGameList: (game_type: string) =>
    axiosRequest.post<CategoryDetailsResponseType>(
      "",
      payloadHelper("GetGameList", game_type, { game_type })
    )
};
