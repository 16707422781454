import { css } from "@emotion/react";

import { SkeletonProps } from ".";

export const SkeletonCustomStyle = {
  index: ({ height }: SkeletonProps) => css`
    background: #eee;
    background: linear-gradient(120deg, #ececec 8%, #d9d9d9 18%, #ececec 33%);
    border-radius: 10px;
    background-size: 200% 100%;
    animation: 1.5s shine linear infinite;
    width: 100%;
    display: block;
    height: ${height};
    &.circle {
      border-radius: 100%;
    }
    @keyframes shine {
      to {
        background-position-x: -200%;
      }
    }
  `
};
