import { css } from "@emotion/react";
import { OrderStatus } from "enums/orders";
import { handleColorStatus } from "pages/PlayRecordPage/showStatusText";
import { theme } from "styles/theme";

export const BettingRecordCardStyle = {
  self: css`
    width: 100%;
    padding: 16px;
    border-radius: 10px;
    background-color: #f5f5f5;
    display: flex;
    justify-content: space-between;
    align-items: center;
  `,

  cardLeft: css`
    /*  */
  `,
  title: () => css`
    color: ${theme.colors.black};
    font-size: 15px;
    margin-bottom: 8px;
    font-weight: 500;
  `,
  createdDate: () => css`
    font-size: 11px;
    color: ${theme.colors.primary[200]};
    font-weight: 500;
  `,

  cardRight: css`
    /*  */
  `,
  amount: () => css`
    color: ${theme.colors.black};
    font-size: 15px;
    font-weight: 700;
    margin-bottom: 6px;
  `,
  status: (status: OrderStatus) => css`
    color: ${handleColorStatus(status)};
    font-size: 11px;
    margin-bottom: 6px;
    font-weight: 500;
    line-height: 12px;
    text-align: center;
  `,
  depositType: () => css`
    color: ${theme.colors.primary[200]};
    font-size: 11px;
    font-weight: 500;
    svg {
      vertical-align: middle;
    }
  `
};
