import ImageTextBoxBg_1 from "assets/images/promotion/promotion-detail-textbox-1.png";
import ImageTextBoxBg_2 from "assets/images/promotion/promotion-detail-textbox-2.png";
import ImageTitleBox_2 from "assets/images/promotion/promotion-detail-title-botttom.png";
import ImageTitleBox_1 from "assets/images/promotion/promotion-detail-title-top.png";

interface TypePromotionDetail {
  image: string;
  key: number;
  title?: string;
  describe?: string;
  number?: string;
}

export const LIST_INFO_TITLE: TypePromotionDetail[] = [
  {
    image: ImageTitleBox_1,
    key: 1
  },
  {
    image: ImageTitleBox_2,
    key: 2
  }
];

export const LIST_CONTENT_TEXTBOX: TypePromotionDetail[] = [
  {
    image: ImageTextBoxBg_1,
    number: "5,515.68",
    title: "Distributed",
    describe: "CYN",
    key: 1
  },
  {
    image: ImageTextBoxBg_2,
    number: "9",
    title: "Participants",
    describe: "member",
    key: 2
  }
];
