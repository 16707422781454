import { css } from "@emotion/react";
import { media } from "styles/breakpoints";
import { theme } from "styles/theme";

export const SideBarStyles = {
  overlay: css`
    position: fixed;
    z-index: 8;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    top: 0;
    left: 0;
    display: none;

    ${media.xxl} {
      display: block;
    }
  `,
  container: (isSidebar: boolean) => css`
    width: ${isSidebar ? "18.75rem" : "84px"};
    background-color: #070b1c;
    transition: 0.2s all;
    overflow: hidden;
    scrollbar-width: 4px;
    z-index: 9;
    &::-webkit-scrollbar {
      width: 6px;
      background-color: ${theme.colors.primary[700]};
    }
    &::-webkit-scrollbar-thumb:hover {
      width: 6px;
      background-color: ${theme.colors.primary[500]};
    }
    &::-webkit-scrollbar-thumb {
      width: 6px;
      background-color: ${theme.colors.primary[500]};
      border-radius: 6px;
    }

    ${media.md} {
      position: fixed;
      left: 0;
      top: 0;
      height: 100%;
      z-index: 11;
      width: ${isSidebar ? "100%" : "0px"};
      background-color: ${theme.colors.primary[900]};
    }
  `,
  main: css`
    height: 100%;
    overflow: hidden;
    display: flex;
    flex-direction: column;
  `,
  btnSlideBar: (isOpen: boolean) => css`
    width: 40px;
    height: 40px;
    margin: 12px 12px 0 12px;
    padding-left: 12px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    svg {
      transform: ${!isOpen ? "rotate(180deg)" : "rotate(0deg)"};
      transition: 0.2s all;
    }

    ${media.md} {
      display: none;
    }
  `,

  content: (open: boolean) => css`
    flex: 1;
    overflow: auto;

    &::-webkit-scrollbar {
      width: 6px;
      background-color: ${theme.colors.primary[700]};
    }
    &::-webkit-scrollbar-thumb:hover {
      width: 6px;
      background-color: ${theme.colors.primary[500]};
    }
    &::-webkit-scrollbar-thumb {
      width: 6px;
      background-color: ${theme.colors.primary[500]};
      border-radius: 6px;
    }

    .item-menu-view {
      .label {
        display: ${open ? "block" : "none"};
        transition: 0.2s all;
      }
      .icon-open {
        display: ${!open && "none"} !important;
      }
    }
  `,
  logoutBox: css`
    display: none;
    margin-top: 16px;
    ${media.md} {
      display: flex;
      padding-left: 16px;
      padding-right: 16px;
      padding-bottom: 32px;
    }

    button {
      flex: 1;
      border: 1px solid ${theme.colors.secondary[900]};
      background-color: transparent;

      span {
        color: ${theme.colors.secondary[900]};
      }
    }
  `
};
