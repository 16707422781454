import T1G from "assets/images/game/crash-game/crash-game_T1G.png";
import { SubGameType } from "types/game";

export const listGameCrashGame: SubGameType[] = [
  {
    game_code: "ACEW",
    name: "Lobby",
    provider_code: "ACEW",
    type: "OT",
    featured: "NO",
    new_arrive: "NO",
    picture: T1G
  }
];
