/** @jsxImportSource @emotion/react */
import { Tooltip } from "antd";
import { ReactComponent as LeftIcon } from "assets/icons/leftIcon.svg";
import SidebarCategoryGame from "components/ui/SidebarCategoryGame";
import { MenuItem } from "constants/list-menu";
import { useAppSelector } from "hooks/app-hook";
import { useRef, useState } from "react";
import { selectOpenSidebar } from "store/modal";

import Menu from "..";
import { MenuItemViewStyle } from "./index.style";
type Props = {
  item: MenuItem;
  onClick: (val: MenuItem) => void;
  order: number;
  itemKey: string;
  onMouseEnter: (id: string) => void;
  idHover: string;
};

function MenuItemView({
  item,
  onClick,
  order,
  itemKey,
  idHover,
  onMouseEnter
}: Props) {
  const openSidebar = useAppSelector(selectOpenSidebar);
  const [isOpen, setIsOpen] = useState(true);
  const refChild = useRef<HTMLDivElement>(null);
  const onCollapse = (val: boolean) => {
    setIsOpen(val);
    if (val) {
      refChild.current?.scrollIntoView(true);
    }
  };
  const isActive = itemKey === item.key;
  const isFocused = idHover === `${item.key}`;
  return (
    <>
      {item.isBorder && <div css={MenuItemViewStyle.dash} />}
      <div css={MenuItemViewStyle.itemContainer}>
        {item.label && (
          <Tooltip
            {...(openSidebar || item.isHover ? { zIndex: -1 } : {})}
            placement="rightBottom"
            title={item.label}
          >
            <div
              onMouseEnter={() => {
                if (item.isHover) {
                  onMouseEnter(item.key);
                } else {
                  onMouseEnter("");
                }
              }}
            >
              <div
                onClick={() => {
                  if (item.isClick) {
                    onClick(item);
                    return;
                  }
                  onCollapse(!isOpen);
                }}
                css={MenuItemViewStyle.itemContent(
                  item.isClick,
                  item.children?.length ? true : false,
                  isFocused
                )}
                className={`item-menu-view ${isActive && "item-menu-active"}`}
              >
                <div className="icon">{item.icon}</div>
                <div className="label">{item.label}</div>

                <div className="icon-open">
                  <LeftIcon />
                </div>
              </div>
              {item.isHover && (
                <SidebarCategoryGame
                  isOpen={isFocused}
                  setIsOpen={(val) => onMouseEnter(val ? item.key : "")}
                  idCategory={item}
                />
              )}
            </div>
          </Tooltip>
        )}
        {isOpen && (
          <div css={MenuItemViewStyle.children}>
            <div ref={refChild}>
              {item.children && item.children.length > 0 && (
                <Menu
                  itemKey={itemKey}
                  items={item.children}
                  onClick={onClick}
                  order={order + 1}
                  idHover={idHover}
                  onMouseEnter={onMouseEnter}
                />
              )}
            </div>
          </div>
        )}
      </div>
    </>
  );
}

export default MenuItemView;
