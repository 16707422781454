import { dataLanguage } from "constants/languages";
import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import HttpBackend from "i18next-http-backend";
import { initReactI18next } from "react-i18next";
const languageDefault = localStorage.getItem("i18nextLng");
i18n
  .use(HttpBackend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    debug: false,
    lng: languageDefault || dataLanguage[0].id,
    initImmediate: false,
    fallbackLng: dataLanguage[0].id,
    defaultNS: ["index"],
    backend: {
      loadPath: `/translation/{{lng}}/{{ns}}.json`
    },
    interpolation: {
      escapeValue: false
    },
    react: {
      useSuspense: false
    },
    saveMissing: true
  });

export { i18n };
