/** @jsxImportSource @emotion/react */

import { yupResolver } from "@hookform/resolvers/yup";
import { Form } from "antd";
import { DepositApi } from "api/deposit";
import InputCustom from "components/base/InputCustom";
import SelectCustom from "components/base/SelectCustom";
import { ResponseCode } from "constants/response";
import { closeBlankWindow, showBlankWindow } from "helpers/openBlankWindow";
import { useAppSelector } from "hooks/app-hook";
import _map from "lodash/map";
import { NextButton } from "pages/TradingPage/components/StepControl/NextButton";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useOutletContext } from "react-router-dom";
import { selectUsername } from "store/auth";
import { outletContextType } from "types";
import {
  BankChannelType,
  DepositOnlineSubmissionFormNew,
  OnlineBankingChannelType,
  PromotionType
} from "types/deposit";
import * as yup from "yup";

import { OnlineDepositStyle } from "./style";

interface Props {
  bankId: string;
}

const OnlineDeposit = ({ bankId }: Props) => {
  const { t } = useTranslation("trading");
  const { openNotification } = useOutletContext<outletContextType>();
  const username = useAppSelector(selectUsername);
  const [isLoadingSubmit, setIsLoadingSubmit] = useState<boolean>(false);
  const [optionChannel, setOptionChannel] = useState<BankChannelType[]>([]);
  const [gatewayOnlineItem, setGatewayOnlineItem] = useState<
    OnlineBankingChannelType | undefined
  >(undefined);

  const { promotion, player_fullname, online_banking_channel, setting } =
    useAppSelector((state) => state.deposit.dataDeposit);

  const schema = yup.object({
    username: yup.string().required(t("deposit.error1")),
    gateway_id: yup.number().required(t("deposit.error1")),
    gateway: yup.string().required(t("deposit.error1")),
    amount: yup
      .number()
      .max(
        Number(gatewayOnlineItem?.max_deposit),
        `${t("deposit.error2")} ${gatewayOnlineItem?.max_deposit}`
      )
      .min(
        Number(gatewayOnlineItem?.min_deposit),
        `${t("deposit.error3")} ${gatewayOnlineItem?.min_deposit}`
      )
      .transform((value) => (Number.isNaN(value) ? null : value))
      .required(t("deposit.error1")),
    channel: yup.string().required(t("deposit.error1"))
  });

  const {
    handleSubmit,
    control,
    formState: { errors },
    reset,
    setValue,
    getValues
  } = useForm<DepositOnlineSubmissionFormNew>({
    mode: "onChange",
    resolver: yupResolver(schema),
    defaultValues: {
      username,
      gateway_id: Number(gatewayOnlineItem?.id),
      gateway: gatewayOnlineItem?.gateway
    }
  });

  const findGateway = useCallback(() => {
    return online_banking_channel.find((item) => item.id === bankId);
  }, [bankId, online_banking_channel]);

  useEffect(() => {
    const foundItem = findGateway();
    setValue("amount", 0);
    setValue("channel", "");

    if (foundItem) {
      setGatewayOnlineItem(foundItem);
      setValue("gateway_id", Number(foundItem.id));
      setValue("gateway", foundItem.gateway);
      setOptionChannel(foundItem.bank ?? []);
    }
  }, [findGateway, bankId]);

  const listOptionPromotions = useMemo(() => {
    const res = _map(promotion, (item: PromotionType) => ({
      label: item.title,
      value: item.id
    }));
    if (promotion.length <= 0) {
      return res;
    }
    return [...[{ label: t("deposit.None"), value: "" }], ...res];
  }, [promotion]);

  const listOptionChannel = useMemo(() => {
    const res = _map(optionChannel, (item: BankChannelType) => ({
      label: item.name,
      value: item.code
    }));
    if (optionChannel.length <= 0) {
      return res;
    }
    return [...[{ label: t("deposit.None"), value: "" }], ...res];
  }, [optionChannel, findGateway]);

  const isValid = () => {
    if (!getValues("amount")) return false;
    if (!gatewayOnlineItem?.id) return false;
    if (!gatewayOnlineItem?.gateway) return false;
    return true;
  };

  const onFormSubmit = async (data: DepositOnlineSubmissionFormNew) => {
    try {
      closeBlankWindow();
      setIsLoadingSubmit(true);
      const response = await DepositApi.depositSubmissionOnline(username, {
        promotion_id: Number(data.promotion_id),
        player_fullname: player_fullname || "",
        ...data
      });
      const { status, data: url, msg } = response.data;
      setIsLoadingSubmit(false);
      if (status !== ResponseCode.SUCCESS) {
        openNotification("error", msg);
        closeBlankWindow();
        return;
      }
      reset();
      openNotification("success", msg);
      (showBlankWindow("DepositOnline")?.location as { href: string }).href =
        url;
      // dispatch(getDepositForm(username));
    } catch (error) {
      closeBlankWindow();
      throw new Error(t("InvalidError"), { cause: error });
    }
  };

  return (
    <div css={OnlineDepositStyle.wrapper}>
      <Form onSubmitCapture={handleSubmit(onFormSubmit)}>
        <div css={OnlineDepositStyle.boxOptions}>
          <h3>{t("deposit.amount")}</h3>
          <div css={OnlineDepositStyle.formInput}>
            <div css={OnlineDepositStyle.inputControl}>
              <InputCustom
                control={control}
                type="number"
                name="amount"
                errors={errors.amount?.message}
                validate_status={errors.amount && "error"}
                placeholder={t("deposit.amount")}
                margin_bottom="0"
              />
            </div>
          </div>
          <span className="text-warning">
            {t("deposit.minAmount")} ${setting.min_deposit || "0"} |{" "}
            {t("deposit.maxAmount")}${setting.max_deposit || "0"}
          </span>
        </div>
        <div css={OnlineDepositStyle.boxOptions}>
          <h3>{t("deposit.promotions")}</h3>
          <div css={OnlineDepositStyle.formInput}>
            <div css={OnlineDepositStyle.inputControl}>
              <SelectCustom
                control={control}
                name={"promotion_id"}
                options={listOptionPromotions}
                className="select-custom"
                placeholder={t("deposit.selectPromotion")}
              />
            </div>
          </div>
        </div>
        <div css={OnlineDepositStyle.boxOptions}>
          <h3>{t("deposit.chooseABank")}</h3>
          <div css={OnlineDepositStyle.formInput}>
            <div css={OnlineDepositStyle.inputControl}>
              <SelectCustom
                control={control}
                name={"channel"}
                options={listOptionChannel}
                className="select-custom"
                placeholder={t("deposit.selectABank")}
                error={errors.channel?.message}
                validate_status={errors.channel && "error"}
              />
            </div>
          </div>
        </div>
        <div css={OnlineDepositStyle.boxOptions}>
          <NextButton
            text={t("Submit")}
            disabled={!isValid()}
            loading={isLoadingSubmit}
          />
        </div>
      </Form>
    </div>
  );
};
export default OnlineDeposit;
