import { ReactComponent as IconAbout } from "assets/images/profile/icon-profile-about.svg";
import { ReactComponent as IconMyBet } from "assets/images/profile/icon-profile-bet.svg";
import { ReactComponent as IconPersonalFavorites } from "assets/images/profile/icon-profile-collection.svg";
import { ReactComponent as IconDeposit } from "assets/images/profile/icon-profile-deposit_1.svg";
import { ReactComponent as IconDepositRecord } from "assets/images/profile/icon-profile-deposit-record.svg";
import { ReactComponent as IconApp } from "assets/images/profile/icon-profile-download.svg";
import { ReactComponent as IconNotification } from "assets/images/profile/icon-profile-notification.svg";
import { ReactComponent as IconEditPassword } from "assets/images/profile/icon-profile-password.svg";
import { ReactComponent as IconPromotions } from "assets/images/profile/icon-profile-promotion.svg";
import { ReactComponent as IconPersonalRebate } from "assets/images/profile/icon-profile-rebate_1.svg";
import { ReactComponent as IconTeamSharing } from "assets/images/profile/icon-profile-share.svg";
import { ReactComponent as IconPersonalVip } from "assets/images/profile/icon-profile-vip.svg";
import { ReactComponent as IconWithdrawal } from "assets/images/profile/icon-profile-withdraw_1.svg";
import { ReactComponent as IconWithdrawalRecord } from "assets/images/profile/icon-profile-withdraw-record.svg";

import { LinkDownloadFileApk } from "./apk";
import PATH from "./path";

export interface TypeFunctionListItem {
  name: string;
  image: React.FunctionComponent;
  id: string;
}

export interface TypeFunctionList {
  title: string;
  key: number;
  content: TypeFunctionListItem[];
}

export const FUNCTION_LIST: TypeFunctionList[] = [
  {
    title: "profile:personal",
    key: 1,
    content: [
      {
        image: IconPersonalVip,
        name: "profile:my_vip",
        id: PATH.profile.myVip
      },
      {
        image: IconPersonalFavorites,
        name: "profile:my_favorites",
        id: PATH.myCollectionPage
      },
      {
        image: IconPersonalRebate,
        name: "profile:rebate",
        id: `${PATH.profile.transaction}?type=3`
      },
      {
        image: IconPromotions,
        name: "profile:promotions",
        id: PATH.promotionPage
      }
    ]
  },
  {
    title: "profile:record",
    key: 2,
    content: [
      {
        image: IconDeposit,
        name: "profile:deposit",
        id: PATH.profile.trading
      },
      {
        image: IconWithdrawal,
        name: "profile:withdrawal",
        id: `${PATH.profile.trading}?type=2`
      },
      {
        image: IconDepositRecord,
        name: "profile:deposit_record",
        id: PATH.profile.transaction
      },
      {
        image: IconWithdrawalRecord,
        name: "profile:withdrawal_record",
        id: `${PATH.profile.transaction}?type=2`
      }
    ]
  },
  {
    title: "profile:transactions",
    key: 3,
    content: [
      {
        image: IconMyBet,
        name: "profile:my_bet",
        id: PATH.profile.myBet
      },
      {
        image: IconEditPassword,
        name: "profile:edit_password",
        id: "change-password"
      },
      {
        image: IconTeamSharing,
        name: "profile:friend_sharing",
        id: PATH.profile.friendSharing
      }
    ]
  },
  {
    title: "profile:auxiliary",
    key: 4,
    content: [
      {
        image: IconApp,
        name: "profile:download_app",
        id: LinkDownloadFileApk
      },
      {
        image: IconAbout,
        name: "profile:about",
        id: ""
      },
      {
        image: IconNotification,
        name: "profile:notification_center",
        id: PATH.profile.notificationCenter
      }
    ]
  }
];
