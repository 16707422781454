/** @jsxImportSource @emotion/react */

import Loading from "components/ui/Loading";
import { useAppDispatch, useAppSelector } from "hooks/app-hook";
import _isEqual from "lodash/isEqual";
import { useEffect, useState } from "react";
import { selectUsername } from "store/auth";
import { selectOpenModal } from "store/modal";
import {
  initialState,
  selectDataWithdraw,
  selectIsLoadingWithdraw
} from "store/withdraw";
import { getWithdrawalForm } from "store/withdraw/withdraw.thunk";
import { ModalTypeValue } from "types/modal";

import { ModalAddBank } from "../../../components/base/Modal/ModalAddBank";
import { AmountStep } from "./AmountSetp.tsx";
import { WithdrawStyle } from "./index.style";
import { WalletStep } from "./WalletStep.tsx";

export const Withdraw = () => {
  const dispatch = useAppDispatch();

  const username = useAppSelector(selectUsername);
  const modalOpen = useAppSelector(selectOpenModal);
  const dataWithdraw = useAppSelector(selectDataWithdraw);
  const isLoading = useAppSelector(selectIsLoadingWithdraw);

  const [step, setStep] = useState<"wallet" | "amount">("wallet");

  const isDataDepositEmpty = _isEqual(
    initialState.dataWithdrawal,
    dataWithdraw
  );

  useEffect(() => {
    if (!username) return;

    if (!isDataDepositEmpty) return;

    dispatch(getWithdrawalForm(username));
  }, [username, isDataDepositEmpty]);

  return (
    <>
      {modalOpen === ModalTypeValue.Bank && <ModalAddBank />}
      <div css={WithdrawStyle.index}>
        {isLoading ? (
          <Loading height={400} center />
        ) : (
          <>
            {step === "wallet" && <WalletStep setStep={setStep} />}
            {step === "amount" && <AmountStep setStep={setStep} />}
          </>
        )}
      </div>
    </>
  );
};
