import { css } from "@emotion/react";
import { media } from "styles/breakpoints";
import { theme } from "styles/theme";

export const DefaultLayoutStyles = {
  main: (background: string) => css`
    background-color: #0e131b;
    background-image: url(${background});
    background-repeat: no-repeat;
    background-size: 100% 100%;
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    height: 100vh;
    overflow: hidden;
    min-width: 325px;
    color: ${theme.colors.white};
  `,
  appContent: () => css`
    flex: 1;
    width: 100%;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    position: relative;

    ${media.md} {
      padding-bottom: 69px;
    }
  `,
  pageContent: () => css`
    width: 100%;
    height: 100%;
    overflow: auto;
    ::-webkit-scrollbar {
      width: auto;
      max-width: 6px;
    }
    ::-webkit-scrollbar-thumb {
      background-color: ${theme.colors.primary[500]};
    }

    ${media.sm} {
      ::-webkit-scrollbar {
        display: none;
      }
    }
  `
};
