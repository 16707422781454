import { css } from "@emotion/react";
import { media } from "styles/breakpoints";

export const TradingPageStyle = {
  tab: () => css`
    margin-top: 28px;
    ${media.md} {
      margin-top: 0;
    }
    .ant-tabs-nav {
      &::before {
        border-bottom: 1px solid #313c74;
      }
      .ant-tabs-nav-list {
        .ant-tabs-tab {
          padding: 12px 24px;
          border-bottom: 1px solid transparent;
          transition: border-bottom 0.3s ease;
          color: #9196af;

          &:hover {
            color: white;
          }
          &-active {
            border-bottom: 1px solid #ffb31a;
            .ant-tabs-tab-btn {
              color: #ffb31a;
            }
          }
        }
        .ant-tabs-ink-bar {
          width: unset !important;
          height: unset !important;
        }
      }
    }
  `
};
