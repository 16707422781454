import { css } from "@emotion/react";
import { media } from "styles/breakpoints";

export const AddBankStyle = {
  modalContainer: css`
    .select-custom .ant-select-selector {
      height: 44px;
    }
    .text-warning {
      color: #ffc107;
      font-size: 14px;
      margin-bottom: 20px;
      display: inline-block;

      &.small {
        font-style: italic;
        font-size: 12px;
      }

      ${media.md} {
        max-width: 100%;
      }
    }

    ${media.md} {
      max-width: 100vw !important;
    }
    .ant-modal-content {
      border-radius: 12px !important;
      overflow: hidden;
      padding: 0px !important;
      background-color: transparent !important;
      ${media.sm} {
        border-radius: 0px !important;
        height: 100vh !important;
        width: 100vw !important;
        overflow-y: auto;
        margin: 0px !important;
        padding: 0px !important;
      }
    }
    .ant-modal-footer {
      display: none !important;
    }
  `
};
