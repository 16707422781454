import axiosRequest from "api";
import { payloadHelper } from "helpers/payload-helper";
import { PromotionsListResponseType } from "types/promotions";

export const promotionsApi = {
  async promotionsList() {
    const response = await axiosRequest.post<PromotionsListResponseType>(
      "",
      payloadHelper("PromotionList", null, null)
    );
    return response.data;
  }
};
