import { css, Theme } from "@emotion/react";
import { media } from "styles/breakpoints";
import { theme } from "styles/theme";

export const PlayRecordPageStyle = {
  self: css`
    ${media.md} {
      padding: 16px;
    }
  `,

  blockCalendar: css`
    display: flex;
    flex-wrap: wrap;
    gap: 8px;
    ${media.md} {
      gap: 5px;
    }
  `,

  buttonCalendar: (isSelect: boolean) => css`
    padding: 12px 48px;
    border-radius: 8px;
    margin-top: 16px;
    ${media.md} {
      margin-top: 8px;
    }
    color: ${isSelect ? theme.colors.white : theme.colors.primary[300]};
    background-color: ${isSelect
      ? theme.colors.secondary[900]
      : theme.colors.primary[500]};
    cursor: pointer;
  `,

  header: () => css`
    display: flex;
    flex-direction: row;
    align-items: center;
    color: ${theme.colors.white};
    ${media.md} {
      color: ${theme.colors.black};
    }
    span {
      font-weight: 500;
      font-size: 18px;
      text-align: center;
      margin-left: 8px;
      padding-top: 4px;
    }
  `,

  listOrders: css`
    display: flex;
    flex-direction: column;
    gap: 4px;
    margin-bottom: 50px;
    .ant-empty-description {
      color: white;
    }
    & .ant-list-item {
      border-block-end: none;
      padding: 0 0 4px 0;
    }
  `,

  infinite: css`
    &::-webkit-scrollbar {
      width: 16px;
    }
    &::-webkit-scrollbar-track {
      background-color: transparent;
    }
    &::-webkit-scrollbar-thumb {
      background-color: #d6dee1;
      border-radius: 20px;
      border: 6px solid transparent;
      background-clip: content-box;
    }
  `,

  pagination: ({ colors }: Theme) => css`
    & .ant-pagination {
      display: flex;
      justify-content: center;
      &-item-active,
      &-item-active:hover {
        border-color: ${colors.secondary[900]};
        a {
          color: ${colors.secondary[900]};
        }
      }
    }
  `,

  fromGroupContainer: () => css`
    display: flex;
    gap: 8px;
    align-items: center;
    margin: 16px 0;

    ${media.sm} {
      flex-wrap: wrap;
      gap: 0;
      /* justify-content: center; */
      justify-content: space-between;
    }
  `,

  fromDash: () => css`
    display: flex;
    flex-direction: column;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    gap: 8px;
    span {
      &:first-of-type {
        color: transparent;
      }
      color: ${theme.colors.white};
    }

    ${media.md} {
      color: ${theme.colors.black};
    }
    ${media.sm} {
      color: ${theme.colors.black};
    }
  `,

  formGroup: () => css`
    display: flex;
    flex-direction: column;
    gap: 8px;
    min-width: 160px;
    width: calc(20% - 8px);

    ${media.xl} {
      width: calc(50% - 8px);
    }
    ${media.md} {
      width: calc(40%);
    }
  `,

  spanDate: (colorContent: string) => css`
    color: ${colorContent};
  `,

  iconCalendar: () => css`
    color: ${theme.colors.secondary[900]};
  `,

  blockEmpty: () => css`
    color: transparent;
  `,

  formSubmit: css`
    display: flex;
    flex-direction: column;
    text-align: center;
    margin-left: 8px;
    gap: 8px;
    ${media.sm} {
      margin-left: 0;
      margin-top: 16px;
      width: 100%;
    }
  `,

  btnSubmit: () => css`
    min-width: 120px;
    height: 44px !important;
    font-weight: 400;
    background-color: ${theme.colors.secondary[900]} !important;
    border: none !important;
    &:not(:disabled):hover {
      background-color: #f13039 !important;
    }
    span {
      color: ${theme.colors.white};
    }

    ${media.sm} {
      min-width: 0;
      width: 100%;
    }
  `,

  empty: css`
    display: none !important;
  `
};
