/** @jsxImportSource @emotion/react */
import { Button } from "antd";
import { ReactComponent as MenuSvg } from "assets/icons/menus/menuLeftArrow.svg";
import { useModalConfirm } from "components/base/ModalConfirm";
import { LinkDownloadFileApk } from "constants/apk";
import { ContactUsData, MenuItem, useMenuSideBar } from "constants/list-menu";
import { maxWidthDevice } from "enums/device";
import { useAppDispatch, useAppSelector } from "hooks/app-hook";
import { useAppLogout } from "hooks/useLogout";
import useWindowSize from "hooks/useWindowSize";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";
import { selectIsAuthenticated } from "store/auth";
import { selectContacts } from "store/contact";
import { getContactList } from "store/contact/contact.thunk";
import { resetDataCategoryGameFilter } from "store/game";
import { selectOpenSidebar, setOpenSidebar } from "store/modal";
import { theme } from "styles/theme";

import Menu from "../Menu";
import HeaderMedium from "./HeaderMedium";
import { SideBarStyles } from "./index.style";
const SideBar = () => {
  const { t } = useTranslation("side-bar");
  const { listMenuSideBar } = useMenuSideBar();
  const navigate = useNavigate();
  const { pathname } = useLocation();

  const { width } = useWindowSize();
  const { contextHolder, modalConfig } = useModalConfirm();
  const onLogout = useAppLogout();

  const dispatch = useAppDispatch();
  const isAuth = useAppSelector(selectIsAuthenticated);
  const isSideBar = useAppSelector(selectOpenSidebar);
  const listDataContact = useAppSelector(selectContacts);

  const [idHover, setIdHover] = useState<string>("");

  const setIsSidebar = (val: boolean) => {
    dispatch(setOpenSidebar(val));
  };

  useEffect(() => {
    if (listDataContact.length > 0) return;

    dispatch(getContactList());
  }, [listDataContact.length]);

  const handleLogout = () => {
    modalConfig({
      title: t("logoutRequest"),
      onSuccess: () => {
        onLogout();
      }
    });
  };

  const handleContactUs = () => {
    return listMenuSideBar.map((item, index) => {
      if (index === listMenuSideBar.length - 1) {
        const data: MenuItem[] = [];
        listDataContact.forEach((i) => {
          const valueItem = ContactUsData.find((e) => i.id === e.id);

          if (valueItem) {
            data.push({ ...valueItem, key: i.URL, isClick: true });
          }
        });
        return {
          ...item,
          children: data
        };
      }
      return item;
    });
  };

  return (
    <>
      <div
        onMouseLeave={() => setIdHover("")}
        id={"sidebar-menu"}
        css={SideBarStyles.container(isSideBar)}
      >
        <div css={SideBarStyles.main}>
          <div
            onClick={() => setIsSidebar(!isSideBar)}
            css={SideBarStyles.btnSlideBar(isSideBar)}
          >
            <MenuSvg fill={theme.colors.white} />
          </div>
          <HeaderMedium setIsSidebar={setIsSidebar} />
          <div css={SideBarStyles.content(isSideBar)}>
            <Menu
              onClick={(e) => {
                if (e.label === "Download App") {
                  window.open(LinkDownloadFileApk, "_blank");
                  return;
                }

                if (e.id) {
                  window.open(e.key);
                  return;
                }
                navigate(`${e?.key}`);
                dispatch(resetDataCategoryGameFilter());
                if (width <= maxWidthDevice.MD) {
                  setIsSidebar(false);
                }
              }}
              items={handleContactUs()}
              itemKey={pathname}
              order={0}
              idHover={idHover}
              onMouseEnter={(id: string) => setIdHover(id)}
            />
            {isAuth && (
              <div css={SideBarStyles.logoutBox}>
                <Button onClick={handleLogout}>
                  <span>Logout</span>
                </Button>
              </div>
            )}
          </div>
        </div>
      </div>
      {isSideBar && (
        <div onClick={() => setIsSidebar(false)} css={SideBarStyles.overlay} />
      )}
      {contextHolder}
    </>
  );
};

export default SideBar;
