import ACEW from "assets/images/game/fishing/fishing_ACEW.png";
import JILI from "assets/images/game/fishing/fishing_JILI.png";
import { SubGameType } from "types/game";

export const listGameFishing: SubGameType[] = [
  {
    game_code: "0",
    name: "Lobby",
    provider_code: "ACEW",
    type: "FH",
    featured: "NO",
    new_arrive: "NO",
    picture: ACEW
  },
  {
    game_code: "0",
    name: "Lobby",
    provider_code: "JILI",
    type: "FH",
    featured: "NO",
    new_arrive: "NO",
    picture: JILI
  }
];
