import axiosRequest from "api";
import { payloadHelper } from "helpers/payload-helper";
import { MemberPayload, MemberVipResponseType } from "types/member";

export const memberApi = {
  async getMemberVip(username: string, payload: MemberPayload) {
    const response = await axiosRequest.post<MemberVipResponseType>(
      "/",
      payloadHelper("MemberVIP", username, payload)
    );
    return response.data;
  }
};
