import LevelFirstIcon from "assets/icons/lv1.png";
import LevelSecondIcon from "assets/icons/lv2.png";
import LevelThirdIcon from "assets/icons/lv3.png";
import LevelFourthIcon from "assets/icons/lv4.png";
// import BlockChainBg from "assets/images/homepage/bgBlockchain.jpg";
import FishingBg from "assets/images/homepage/bgFishing.jpg";
import BgHotGame1 from "assets/images/homepage/bgHotGame1.jpg";
import BgHotGame2 from "assets/images/homepage/bgHotGame2.jpg";
import BgHotGame3 from "assets/images/homepage/bgHotGame3.jpg";
import BgHotGame4 from "assets/images/homepage/bgHotGame4.jpg";
import BgHotGame5 from "assets/images/homepage/bgHotGame5.jpg";
import BgHotGame6 from "assets/images/homepage/bgHotGame6.jpg";
import LivenBg from "assets/images/homepage/bgLive.jpg";
import SlotBg from "assets/images/homepage/bgSlot.jpg";
import SportBg from "assets/images/homepage/bgSport.jpg";
// import BlockChain from "assets/images/homepage/blockchain.png";
import Fishing from "assets/images/homepage/fishing.png";
import Live from "assets/images/homepage/live.png";
import Slot from "assets/images/homepage/slotPerson.png";
import Sport from "assets/images/homepage/sportHome.png";
import Lottery1 from "assets/images/lottery1.jpg";
import Lottery2 from "assets/images/lottery2.jpg";
import Sport1 from "assets/images/sport.jpg";
import Sport2 from "assets/images/sport2.jpg";

export const listBanner = [
  {
    picture:
      "https://cdn.hg66sdt65nfx64.com//backend/gallery/66/%E4%BA%8F%E6%8D%9F%E6%95%91%E6%8F%B4%E9%87%91_en_049.png"
  },
  {
    picture:
      "https://cdn.hg66sdt65nfx64.com//backend/gallery/66/%E4%B8%8D%E8%AE%A1%E8%BE%93%E8%B5%A2%E5%A4%A9%E5%A4%A9%E6%B4%97%E7%A0%81%E8%BF%94%E6%B0%B4_en_864.png"
  },
  {
    picture:
      "https://cdn.hg66sdt65nfx64.com//backend/gallery/66/%E4%BA%8F%E6%8D%9F%E6%95%91%E6%8F%B4%E9%87%91_en_049.png"
  },
  {
    picture:
      "https://cdn.hg66sdt65nfx64.com//backend/gallery/66/%E4%B8%8D%E8%AE%A1%E8%BE%93%E8%B5%A2%E5%A4%A9%E5%A4%A9%E6%B4%97%E7%A0%81%E8%BF%94%E6%B0%B4_en_864.png"
  },
  {
    picture:
      "https://cdn.hg66sdt65nfx64.com//backend/gallery/66/%E4%BA%8F%E6%8D%9F%E6%95%91%E6%8F%B4%E9%87%91_en_049.png"
  },
  {
    picture:
      "https://cdn.hg66sdt65nfx64.com//backend/gallery/66/%E4%B8%8D%E8%AE%A1%E8%BE%93%E8%B5%A2%E5%A4%A9%E5%A4%A9%E6%B4%97%E7%A0%81%E8%BF%94%E6%B0%B4_en_864.png"
  },
  {
    picture:
      "https://cdn.hg66sdt65nfx64.com//backend/gallery/66/%E4%BA%8F%E6%8D%9F%E6%95%91%E6%8F%B4%E9%87%91_en_049.png"
  },
  {
    picture:
      "https://cdn.hg66sdt65nfx64.com//backend/gallery/66/%E4%B8%8D%E8%AE%A1%E8%BE%93%E8%B5%A2%E5%A4%A9%E5%A4%A9%E6%B4%97%E7%A0%81%E8%BF%94%E6%B0%B4_en_864.png"
  }
];
export const listGameHot = [
  {
    picture: BgHotGame4,
    name: "Buffalo King Megaways"
  },
  {
    picture: BgHotGame1,
    name: "Striking Hot 5"
  },
  {
    picture: BgHotGame3,
    name: "Bigger Bass Bonanza"
  },
  {
    picture: BgHotGame2,
    name: "Money Roll"
  },
  {
    picture: BgHotGame5,
    name: "Fire 88"
  },
  {
    picture: BgHotGame6,
    name: "Aztec King"
  }
];

export const listBonus = [
  {
    _id: "1",
    price: 1097.02,
    picture:
      "https://cdn.hg66sdt65nfx64.com/upload-images/platform-game/material-package-two/PP_SLOT_$$vs1money$$_recommend.webp"
  },
  {
    _id: "2",
    price: 1097.02,
    picture:
      "https://cdn.hg66sdt65nfx64.com/upload-images/platform-game/material-package-two/PP_SLOT_$$vs1money$$_recommend.webp"
  },
  {
    _id: "3",
    price: 1097.02,
    picture:
      "https://cdn.hg66sdt65nfx64.com/upload-images/platform-game/material-package-two/PP_SLOT_$$vs1money$$_recommend.webp"
  },
  {
    _id: "4",
    price: 1097.02,
    picture:
      "https://cdn.hg66sdt65nfx64.com/upload-images/platform-game/material-package-two/PP_SLOT_$$vs1money$$_recommend.webp"
  },
  {
    _id: "5",
    price: 1097.02,
    picture:
      "https://cdn.hg66sdt65nfx64.com/upload-images/platform-game/material-package-two/PP_SLOT_$$vs1money$$_recommend.webp"
  },
  {
    _id: "6",
    price: 1097.02,
    picture:
      "https://cdn.hg66sdt65nfx64.com/upload-images/platform-game/material-package-two/PP_SLOT_$$vs1money$$_recommend.webp"
  },
  {
    _id: "7",
    price: 1097.02,
    picture:
      "https://cdn.hg66sdt65nfx64.com/upload-images/platform-game/material-package-two/PP_SLOT_$$vs1money$$_recommend.webp"
  },
  {
    _id: "8",
    price: 1097.02,
    picture:
      "https://cdn.hg66sdt65nfx64.com/upload-images/platform-game/material-package-two/PP_SLOT_$$vs1money$$_recommend.webp"
  },
  {
    _id: "9",
    price: 1097.02,
    picture:
      "https://cdn.hg66sdt65nfx64.com/upload-images/platform-game/material-package-two/PP_SLOT_$$vs1money$$_recommend.webp"
  },
  {
    _id: "10",
    price: 1097.02,
    picture:
      "https://cdn.hg66sdt65nfx64.com/upload-images/platform-game/material-package-two/PP_SLOT_$$vs1money$$_recommend.webp"
  },
  {
    _id: "11",
    price: 1097.02,
    picture:
      "https://cdn.hg66sdt65nfx64.com/upload-images/platform-game/material-package-two/PP_SLOT_$$vs1money$$_recommend.webp"
  },
  {
    _id: "12",
    price: 1097.02,
    picture:
      "https://cdn.hg66sdt65nfx64.com/upload-images/platform-game/material-package-two/PP_SLOT_$$vs1money$$_recommend.webp"
  }
];
export const listTopCharts = [
  {
    _id: "1",
    name: "SABA Sport",
    level: LevelFirstIcon,
    picture: Sport1,
    connect: "2,389,186.9"
  },
  {
    _id: "2",
    name: "HK6",
    level: LevelSecondIcon,
    picture: Lottery1,
    connect: "1,389,186.9"
  },
  {
    _id: "3",
    level: LevelThirdIcon,
    name: "FB Sport",
    picture: Sport2,
    connect: "521,183.9"
  },
  {
    _id: "4",
    name: "MOLHC",
    level: LevelFourthIcon,
    picture: Lottery2,
    connect: "484,113.4"
  }
];

export const listRecent = [
  {
    gameName: "AG Live",
    players: "0pppppppko",
    time: "2023/07/03 15:23",
    bet: "102.7",
    odds: "31.57x",
    profit: "3,242.2"
  },
  {
    gameName: "Candy Quest",
    players: "0pppppppko",
    time: "2023/07/03 15:23",
    bet: "102.7",
    odds: "31.57x",
    profit: "3,242.2"
  },
  {
    gameName: "King Arthur",
    players: "0pppppppko",
    time: "2023/07/03 15:23",
    bet: "102.7",
    odds: "31.57x",
    profit: "3,242.2"
  },
  {
    gameName: "Emoji Riches",
    players: "0pppppppko",
    time: "2023/07/03 15:23",
    bet: "102.7",
    odds: "31.57x",
    profit: "3,242.2"
  },
  {
    gameName: "The Nutcracker",
    players: "0pppppppko",
    time: "2023/07/03 15:23",
    bet: "102.7",
    odds: "31.57x",
    profit: "3,242.2"
  },
  {
    gameName: "Magic Gem",
    players: "0pppppppko",
    time: "2023/07/03 15:23",
    bet: "102.7",
    odds: "31.57x",
    profit: "3,242.2"
  },
  {
    gameName: "Kabuki Gold",
    players: "0pppppppko",
    time: "2023/07/03 15:23",
    bet: "102.7",
    odds: "31.57x",
    profit: "3,242.2"
  },
  {
    gameName: "AG Live",
    players: "0pppppppko",
    time: "2023/07/03 15:23",
    bet: "102.7",
    odds: "31.57x",
    profit: "3,242.2"
  },
  {
    gameName: "Candy Quest",
    players: "0pppppppko",
    time: "2023/07/03 15:23",
    bet: "102.7",
    odds: "31.57x",
    profit: "3,242.2"
  },
  {
    gameName: "AG Live",
    players: "0pppppppko",
    time: "2023/07/03 15:23",
    bet: "102.7",
    odds: "31.57x",
    profit: "3,242.2"
  },
  {
    gameName: "Candy Quest",
    players: "0pppppppko",
    time: "2023/07/03 15:23",
    bet: "102.7",
    odds: "31.57x",
    profit: "3,242.2"
  },
  {
    gameName: "King Arthur",
    players: "0pppppppko",
    time: "2023/07/03 15:23",
    bet: "102.7",
    odds: "31.57x",
    profit: "3,242.2"
  },
  {
    gameName: "Emoji Riches",
    players: "0pppppppko",
    time: "2023/07/03 15:23",
    bet: "102.7",
    odds: "31.57x",
    profit: "3,242.2"
  },
  {
    gameName: "The Nutcracker",
    players: "0pppppppko",
    time: "2023/07/03 15:23",
    bet: "102.7",
    odds: "31.57x",
    profit: "3,242.2"
  },
  {
    gameName: "Emoji Riches",
    players: "0pppppppko",
    time: "2023/07/03 15:23",
    bet: "102.7",
    odds: "31.57x",
    profit: "3,242.2"
  },
  {
    gameName: "The Nutcracker",
    players: "0pppppppko",
    time: "2023/07/03 15:23",
    bet: "102.7",
    odds: "31.57x",
    profit: "3,242.2"
  },
  {
    gameName: "Magic Gem",
    players: "0pppppppko",
    time: "2023/07/03 15:23",
    bet: "102.7",
    odds: "31.57x",
    profit: "3,242.2"
  },
  {
    gameName: "Kabuki Gold",
    players: "0pppppppko",
    time: "2023/07/03 15:23",
    bet: "102.7",
    odds: "31.57x",
    profit: "3,242.2"
  }
];

export const listTitleRecent = [
  "Game Name",
  "Player",
  "Time",
  "Bet",
  "Odds",
  "Profit"
];

export const listCategory = [
  {
    id: "live",
    name: "Live",
    content: "Casino are live streaming, fairness and openness guaranteed.",
    background: LivenBg,
    image: Live
  },
  {
    id: "slots",
    name: "Slots",
    content: "Slot games are available in various styles for your selection.",
    background: SlotBg,
    image: Slot
  },
  {
    id: "sports",
    name: "Sport",
    content:
      "High odds, higher profits. The more you bet, the more bonus you earn!",
    background: SportBg,
    image: Sport
  },
  // {
  //   id: "e-sport",
  //   name: "ESport",
  //   content:
  //     "Automated and efficient smart contracts to provide you with the fairest results.",
  //   background: BlockChainBg,
  //   image: BlockChain,
  // },
  {
    id: "fishing",
    name: "Fishing",
    content: "Easy to play and super fun. Anyone can become a fishing master!",
    background: FishingBg,
    image: Fishing
  }
];

export const listSort = [
  {
    id: "all",
    name: "All"
  },
  {
    id: "featured",
    name: "Hot"
  }
  // {
  //   id: "fav",
  //   name: "Favorite",
  //   icon: FavoriteIcon,
  //   value: "new_arrive",
  // },
];
