import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ApplicationRootState } from "types";
import { WithdrawStateType } from "types/withdraw";

import { extraReducers } from "./withdraw.extraReducers";

export const initialState: WithdrawStateType = {
  isLoading: false,
  dataWithdrawal: {
    player_bank: [],
    currencies: [],
    setting: {
      min_withdrawal: 0,
      max_withdrawal: 0
    }
  },
  dataWithdrawalSubMission: {
    currency_id: "",
    player_bank_id: ""
  }
};

const withdrawSlice = createSlice({
  name: "withdraw",
  initialState,
  reducers: {
    setDefaultWithdrawlSubMission(state: WithdrawStateType) {
      if (state.dataWithdrawal.currencies.length > 0) {
        state.dataWithdrawalSubMission.currency_id =
          state.dataWithdrawal.currencies[0].currency_id;
      }

      if (state.dataWithdrawal.player_bank.length > 0) {
        state.dataWithdrawalSubMission.player_bank_id =
          state.dataWithdrawal.player_bank[0].player_bank_id;
      }
    },
    setCurrencyId(state: WithdrawStateType, action: PayloadAction<string>) {
      state.dataWithdrawalSubMission.currency_id = action.payload;
    },
    setPlayerBankId(state: WithdrawStateType, action: PayloadAction<string>) {
      state.dataWithdrawalSubMission.player_bank_id = action.payload;
    }
  },
  extraReducers
});

export const selectDataWithdraw = (state: ApplicationRootState) =>
  state.withdraw.dataWithdrawal;

export const selectIsLoadingWithdraw = (state: ApplicationRootState) =>
  state.withdraw.isLoading;

export const selectDataWithdrawalSubMission = (state: ApplicationRootState) =>
  state.withdraw.dataWithdrawalSubMission;

export const { setDefaultWithdrawlSubMission, setCurrencyId, setPlayerBankId } =
  withdrawSlice.actions;

export default withdrawSlice.reducer;
