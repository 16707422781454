import { TypeListCollection } from "components/ui/ListProvider/type";

export const LIST_PROVIDERS: TypeListCollection[] = [
  {
    name: "All",
    id: "all"
  },
  {
    name: "PM",
    id: "pm",
    data: "0"
  },
  {
    name: "WALI",
    id: "wali",
    data: "0"
  },
  {
    name: "WE",
    id: "we",
    data: "0"
  },
  {
    name: "EVOLUTION",
    id: "evolution",
    data: "0"
  },
  {
    name: "SEXY",
    id: "sexy",
    data: "0"
  },
  {
    name: "AG",
    id: "ag",
    data: "0"
  },
  {
    name: "BG",
    id: "bg",
    data: "0"
  },
  {
    name: "SA",
    id: "sa",
    data: "0"
  },
  {
    name: "MG",
    id: "mg",
    data: "0"
  },
  {
    name: "PP",
    id: "pp",
    data: "0"
  },
  {
    name: "BBIN",
    id: "bbin",
    data: "0"
  },
  {
    name: "RICH88",
    id: "rich88",
    data: "0"
  },
  {
    name: "PNG",
    id: "png",
    data: "0"
  },
  {
    name: "JILI",
    id: "jili",
    data: "0"
  },
  {
    name: "JDB",
    id: "jdb",
    data: "0"
  },
  {
    name: "PG",
    id: "pg",
    data: "0"
  },
  {
    name: "ROYAL",
    id: "royal",
    data: "0"
  },
  {
    name: "REEVO",
    id: "reevo",
    data: "0"
  },
  {
    name: "FG",
    id: "fg",
    data: "0"
  },
  {
    name: "KY",
    id: "ky",
    data: "0"
  },

  {
    name: "TL",
    id: "tl",
    data: "0"
  },
  {
    name: "UG",
    id: "ug",
    data: "0"
  },
  {
    name: "CROWN",
    id: "crown",
    data: "0"
  },
  {
    name: "SABA",
    id: "saba",
    data: "0"
  },
  {
    name: "FB",
    id: "fb",
    data: "0"
  },

  {
    name: "BL",
    id: "bl",
    data: "0"
  },
  {
    name: "DS88",
    id: "ds88",
    data: "0"
  },
  {
    name: "S128",
    id: "s128",
    data: "0"
  },
  {
    name: "CB",
    id: "cb",
    data: "0"
  }
];
