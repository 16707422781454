import { css } from "@emotion/react";
import { theme } from "styles/theme";

export const ProfileDropdownStyle = {
  buttonProfile: css`
    cursor: pointer;
    width: 48px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
  `,
  content: css`
    width: 250px;
    min-height: 100px;
    border-radius: 12px;

    background: linear-gradient(
      42deg,
      rgba(20, 20, 62, 1) 0%,
      rgba(43, 49, 93, 0.9893207282913166) 35%,
      rgba(5, 4, 66, 1) 100%
    );
  `,
  info: css`
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 24px 40px;
    span {
      color: #fff;
      font-size: 12px;
      font-style: normal;
      font-weight: 600;
      line-height: 18px;
      margin-top: 8px;
      text-align: center;
    }
    img {
      height: 48px;
      width: 48px;
      border-radius: 50%;
    }

    border-bottom: 1px solid ${theme.colors.primary[600]};
    img {
      width: 54px;
      height: 54px;
      object-fit: contain;
    }
  `,
  listMenu: css`
    display: flex;
    flex-direction: column;

    .item-menu {
      display: flex;
      flex-direction: row;
      align-items: center;
      text-decoration: none;
      justify-content: space-between;
      padding-left: 30px;

      padding-top: 10px;
      padding-bottom: 10px;
      &:first-of-type {
        margin-top: 18px;
      }
      &:last-of-type {
        margin-bottom: 6px;
      }
      &:hover {
        svg {
          fill: ${theme.colors.secondary[900]};
        }
        .arrow-icon {
          transform: translateX(-10px);
          opacity: 1;
          transition: 0.25s;
        }
        .item-wrap {
          span {
            color: ${theme.colors.secondary[900]};
          }
        }
      }
      .item-wrap {
        display: flex;
        align-items: center;
        span {
          color: ${theme.colors.white};
          margin-left: 8px;
          font-size: 14px;
          font-weight: 400;
        }
      }
      .arrow-icon {
        transform: translateX(-30px);
        opacity: 0;
        transition: 0.25s;
      }

      svg {
        fill: ${theme.colors.white};
      }
    }
  `,
  logoutButton: css`
    background-color: #242a44;
    border-bottom-left-radius: 12px;
    border-bottom-right-radius: 12px;
    .buttonLogout {
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      span {
        color: ${theme.colors.primary[300]};
        font-size: 12px;
        font-style: normal;
        font-weight: 600;
        line-height: 18px;
        transition: 0.25s;
        padding: 19px 102px;
      }

      &:hover {
        span {
          color: ${theme.colors.secondary[900]};
          transition: 0.25s;
        }
      }
    }
  `
};
