/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { ReactComponent as BoardGame } from "assets/icons/menus/icon-board-game.svg";
import { ReactComponent as CasioSvg } from "assets/icons/menus/icon-casino-home.svg";
import { ReactComponent as IconDownload } from "assets/icons/menus/icon-download.svg";
import { ReactComponent as Facebook } from "assets/icons/menus/icon-fb.svg";
import { ReactComponent as Fishing } from "assets/icons/menus/icon-fish.svg";
import { ReactComponent as GameSvg } from "assets/icons/menus/icon-game.svg";
import { ReactComponent as Instagram } from "assets/icons/menus/icon-instagram.svg";
import { ReactComponent as LineChat } from "assets/icons/menus/icon-line.svg";
import { ReactComponent as Live } from "assets/icons/menus/icon-live.svg";
import { ReactComponent as FriendSvg } from "assets/icons/menus/icon-profile-share.svg";
import { ReactComponent as PromotionsSvg } from "assets/icons/menus/icon-promotion.svg";
import { ReactComponent as Service } from "assets/icons/menus/icon-service-sidebar.svg";
import { ReactComponent as Slot } from "assets/icons/menus/icon-slot.svg";
import { ReactComponent as Telegram } from "assets/icons/menus/icon-telegram.svg";
import { useTranslation } from "react-i18next";

import { LinkDownloadFileApk } from "./apk";
import { dataLanguage } from "./languages";
import PATH from "./path";

export type MenuItem = {
  label: React.ReactNode;
  key: string;
  icon?: React.ReactNode;
  children?: MenuItem[];
  className?: string;
  isClick?: boolean;
  isBorder?: boolean;
  isHover?: boolean;
  categoryId?: string;
  providerCode?: string;
  id?: string;
};

export const useMenuSideBar = () => {
  const { t, i18n } = useTranslation("side-bar");
  const currentItem = dataLanguage.find((i) => i.id === i18n.language);
  const listMenuSideBar: MenuItem[] = [
    {
      label: "",
      key: "menu1",
      children: [
        {
          label: t("CasinoHome"),
          key: PATH.home,
          icon: <CasioSvg />,
          isClick: true
        },
        // {
        //   label: "My Collection",
        //   key: PATH.myCollectionPage,
        //   icon: <CollectSvg />,
        //   isClick: true,
        // },
        {
          label: t("PlayRecord"),
          key: PATH.playRecordPage,
          icon: <GameSvg />,
          isClick: true
        },
        {
          label: t("Promotions"),
          key: PATH.promotionPage,
          icon: <PromotionsSvg />,
          isClick: true
        },
        {
          label: t("FriendSharing"),
          key: PATH.profile.friendSharing,
          icon: <FriendSvg />,
          isClick: true
        }
      ]
    },
    {
      label: "",
      key: "menu2",
      isBorder: true,
      children: [
        {
          label: t("Live"),
          key: "/live",
          icon: <Live />,
          isClick: true,
          isHover: true,
          categoryId: "LC"
        },
        {
          label: t("Slots"),
          key: "/slots",
          icon: <Slot />,
          isClick: true,
          isHover: true,
          categoryId: "SL"
        },
        // {
        //   label: "Sports",
        //   key: "/sports",
        //   icon: <Sport />,
        //   isClick: true,
        //   isHover: true,
        //   categoryId: "SB",
        // },
        // {
        //   label: "E Sport",
        //   key: "/e-sport",
        //   icon: <Blockchain />,
        //   isHover: true,
        //   isClick: true,
        //   categoryId: "ES",
        // },
        {
          label: t("Fishing"),
          key: "/fishing",
          icon: <Fishing />,
          isClick: true,
          isHover: true,
          categoryId: "FH"
        },
        {
          label: t("Arcade"),
          key: "/arcade-game",
          icon: <BoardGame />,
          isClick: true,
          isHover: true,
          categoryId: "OT",
          providerCode: "T1G"
        }
        // {
        //   label: "Sabong",
        //   key: "/sabong",
        //   icon: <AnimalContest />,
        //   isClick: true,
        //   isHover: true,
        //   categoryId: "CF",
        // },
        // {
        //   label: "Table & Card",
        //   key: "/table-card",
        //   icon: <FriendSvg />,
        //   isClick: true,
        //   isHover: true,
        //   categoryId: "BG",
        //   providerCode: "JILI",
        // },
      ]
    },
    {
      label: currentItem?.name,
      key: "#",
      isClick: true,
      isBorder: true,
      icon: (
        <img
          src={currentItem?.img}
          css={css`
            width: 20px;
            height: 20px;
          `}
          alt=""
        />
      )
    },
    {
      label: t("DownloadApp"),
      key: LinkDownloadFileApk,
      icon: <IconDownload />,
      isClick: true,
      isBorder: true
    },
    {
      label: t("Contact"),
      key: "#Contact",
      icon: <Service />,
      isBorder: true,
      isClick: true
    }
  ];
  return { listMenuSideBar };
};

export const ContactUsData = [
  {
    label: "Telegram",
    key: "#",
    icon: <Telegram />,
    id: "3"
  },
  {
    label: "Facebook",
    key: "#",
    icon: <Facebook />,
    id: "6"
  },
  {
    label: "Instagram",
    key: "#",
    icon: <Instagram />,
    id: "7"
  },
  {
    label: "Live Chat",
    key: "#",
    icon: <LineChat />,
    id: "12"
  }
];
