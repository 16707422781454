import { css } from "@emotion/react";
import { media } from "styles/breakpoints";
import { theme } from "styles/theme";

export const LoginModalStyle = {
  modalContainer: css`
    ${media.md} {
      max-width: 100vw !important;
    }
    .ant-modal-content {
      border-radius: 12px !important;
      overflow: hidden;
      padding: 0px !important;
      background-color: transparent !important;
      ${media.sm} {
        border-radius: 0px !important;
        height: 100vh !important;
        width: 100vw !important;
        overflow-y: auto;
        margin: 0px !important;
        padding: 0px !important;
      }
    }
    .ant-modal-footer {
      display: none !important;
    }
  `,
  formContainer: css`
    display: flex;
    flex-direction: column;
    margin-left: 32px;
    margin-right: 32px;

    ${media.md} {
      margin-left: 0;
      margin-right: 0;
    }
  `,
  inputControl: css`
    margin-bottom: 22px;
    label {
      color: ${theme.colors.primary[100]};
      font-size: 16px;
      font-weight: 500;
      line-height: 25.144px;
    }

    .ant-form-item {
      margin-top: 8px;
    }
  `,
  forgetPass: () => css`
    margin-top: 8px;
    font-weight: 500;
    font-size: 13px;
    line-height: 24px;
    text-align: center;
    color: ${theme.colors.primary[1000]};
    cursor: pointer;
  `,

  buttonSubmit: () => css`
    height: 40px;
    background: ${theme.colors.secondary[900]} !important;
    opacity: 0.5;
    border-radius: 20px;
    border: 0px;
    margin-top: 24px;
    color: ${theme.colors.white} !important;

    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;

    cursor: inherit;
  `,

  buttonActive: () => css`
    background-color: ${theme.colors.secondary[900]} !important;
    color: ${theme.colors.white} !important;
    cursor: pointer;
    opacity: 1;
  `,

  footer: () => css`
    margin-top: 16px;
    text-align: center;
    font-weight: 500;
    font-size: 13px;
    line-height: 20px;
    color: ${theme.colors.primary[100]};
    margin-bottom: 6px;

    span {
      color: ${theme.colors.primary[1000]};
      cursor: pointer;

      &:hover {
        text-decoration: underline;
      }
    }
  `
};
