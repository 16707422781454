/** @jsxImportSource @emotion/react */

import Loading from "components/ui/Loading";
import { useVerify } from "hooks/useVerify";
import { ReactNode } from "react";

import { UserWrapperPageStyle } from "./index.style";

interface Props {
  children: ReactNode;
  hasMaxWidth?: boolean;
  className?: string;
  style?: React.CSSProperties;
  containerStyle?: React.CSSProperties;
}

export const UserWrapperPage = (props: Props) => {
  const {
    children,
    hasMaxWidth = false,
    className = "",
    style,
    containerStyle
  } = props;
  const { isLoading } = useVerify();
  if (isLoading) return <Loading height={500} width={"100%"} center />;
  return (
    <div css={UserWrapperPageStyle.wrapper} className={className} style={style}>
      <div
        css={UserWrapperPageStyle.container(hasMaxWidth)}
        style={containerStyle}
      >
        {children}
      </div>
    </div>
  );
};
