import { createSlice } from "@reduxjs/toolkit";
import { ApplicationRootState } from "types";
import { GameStateType } from "types/game";

import { extraReducers } from "./game.extraReducers";
import { reducers } from "./game.reducers";

export const initialState: GameStateType = {
  isLoading: false,
  dataCategoryDetails: [], // data from api GetGameList
  dataCategoryGameAll: [],
  dataCategoryGameFilter: [], //filter by game_type
  dataHotGame: {
    data: [],
    totalCount: 0,
    totalPages: 0
  },
  dataSubGame: {
    data: [],
    totalCount: 0,
    totalPages: 0
  },
  dataAllSubGame: {
    data: [],
    totalCount: 0,
    totalPages: 0
  },
  categoryCurrent: ""
};

const gameSlice = createSlice({
  name: "game",
  initialState,
  reducers,
  extraReducers
});

export const selectDataCategoryGameAll = (state: ApplicationRootState) =>
  state.game.dataCategoryGameAll;

export const selectDataCategoryGameFilter = (state: ApplicationRootState) =>
  state.game.dataCategoryGameFilter;

export const selectDataHotGame = (state: ApplicationRootState) =>
  state.game.dataHotGame;

export const selectIsLoading = (state: ApplicationRootState) =>
  state.game.isLoading;

export const selectCategoryCurrent = (state: ApplicationRootState) =>
  state.game.categoryCurrent;

export const selectCategoryDetails = (state: ApplicationRootState) =>
  state.game.dataCategoryDetails;

export const selectDataSubGame = (state: ApplicationRootState) =>
  state.game.dataSubGame;

export const selectAllDataSubGame = (state: ApplicationRootState) =>
  state.game.dataAllSubGame;

export const {
  resetDataCategoryGameFilter,
  setCategoryCurrent,
  resetDataSubGame
} = gameSlice.actions;

export default gameSlice.reducer;
