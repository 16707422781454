import loadable from "@loadable/component";
import App from "App";
import PATH from "constants/path";
import DefaultLayout from "layouts/DefaultLayout";
import SubGamePage from "pages/Game/SubGamePage";
import MemberVipPage from "pages/MemberVipPage";
import PercentagePage from "pages/MemberVipPage/PercentagePage";
import MyCollectionPage from "pages/MyCollectionPage";
import NotificationCenter from "pages/NotificationCenterPage";
import PlayRecordPage from "pages/PlayRecordPage";
import ProfileInfoPage from "pages/ProfileInfoPage";
import PromotionDetailPage from "pages/PromotionDetailPage";
import PromotionPage from "pages/PromotionPage";
import TradingPage from "pages/TradingPage";
import { RouteObject } from "react-router-dom";

import CashLineRoute from "./CashLineRoute";

const HomePage = loadable(() => import("pages/HomePage"));
// const LotteryPage = loadable(() => import("pages/Lottery"));
const CategoryGamePage = loadable(() => import("pages/Game/CategoryGamePage"));
const MyBetPage = loadable(() => import("pages/MyBet"));
const TransactionPage = loadable(() => import("pages/TransactionPage"));
const FriendSharing = loadable(() => import("pages/FriendSharing"));

const routesConfig: RouteObject[] = [
  {
    path: "/",
    element: <App />,
    children: [
      {
        element: <DefaultLayout />,
        children: [
          { path: PATH.referCodeAgent, element: <HomePage /> },
          {
            path: PATH.home,
            element: <HomePage />
          },
          // { block lại từ từ xài
          //   path: PATH.lottery,
          //   element: <LotteryPage />,
          // },
          {
            path: PATH.myCollectionPage,
            element: <MyCollectionPage />
          },
          {
            path: PATH.playRecordPage,
            element: <PlayRecordPage />
          },
          {
            path: PATH.promotionPage,
            element: <PromotionPage />
          },
          {
            path: PATH.promotionDetailPage,
            element: <PromotionDetailPage />
          },
          {
            path: PATH.categoryGamePage,
            element: <CategoryGamePage />
          },
          {
            path: PATH.subGamePage,
            element: <SubGamePage />
          },
          {
            path: PATH.profile.myBet,
            element: <MyBetPage />
          },
          {
            path: PATH.profile.myVip,
            element: <MemberVipPage />
          },
          {
            path: PATH.profile.rebate,
            element: <PercentagePage />
          },
          {
            path: PATH.profile.home,
            element: <ProfileInfoPage />
          },
          {
            path: PATH.profile.friendSharing,
            element: <FriendSharing />
          },
          {
            path: PATH.profile.notificationCenter,
            element: <NotificationCenter />
          }
        ]
      },
      {
        element: <CashLineRoute />,
        children: [
          {
            element: <DefaultLayout />,
            children: [
              {
                path: PATH.profile.trading,
                element: <TradingPage />
              },
              {
                path: PATH.profile.transaction,
                element: <TransactionPage />
              }
            ]
          }
        ]
      }
    ]
  }
];

export default routesConfig;
