import { css } from "@emotion/react";
import { theme } from "styles/theme";

export const ModalConfirmStyles = {
  formContainer: () => css`
    background-color: ${theme.colors.primary[500]};
  `,

  header: () => css`
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    flex-direction: column;
    line-height: 25px;
    text-align: center;
    color: ${theme.colors.white};
    padding: 24px 0;
    gap: 8px;
    span:nth-of-type(odd) {
      font-style: normal;
      font-weight: 700;
      font-size: ${theme.fontSize[16]};
      line-height: 20px;

      color: ${theme.colors.neutrals[100]};
    }
    span:nth-of-type(even) {
      color: ${theme.colors.neutrals[100]};

      font-style: normal;
      font-weight: 500;
      font-size: ${theme.fontSize[14]};
      line-height: 18px;
    }
  `,

  contentForm: () => css`
    display: flex;
    width: 100%;
  `,

  buttonRadioCancel: () => css`
    text-align: center;
    flex: 1;
    padding: 10px 0;
    height: auto;

    border-top-left-radius: 0;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    border-right-color: transparent;
    background-color: ${theme.colors.primary[500]};
  `,
  buttonRadioOk: () => css`
    flex: 1;
    text-align: center;
    padding: 10px 0;
    height: auto;
    background-color: ${theme.colors.primary[500]};
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    border-bottom-left-radius: 0;
    border-right-width: 0;
    &:hover {
      color: ${theme.colors.neutrals[100]} !important;
    }
  `,

  textButton: () => css`
    font-style: normal;
    font-weight: 500;
    font-size: ${theme.fontSize[16]};
    line-height: 20px;
    color: ${theme.colors.neutrals[100]};
    border-color: ${theme.colors.primary[700]} !important;
  `
};
