import { css, keyframes } from "@emotion/react";
import { media } from "styles/breakpoints";
import { theme } from "styles/theme";
export const spin = keyframes`
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
`;
export const WalletDropdownStyle = {
  container: css`
    background-color: ${theme.colors.primary[500]};
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 10px 12px;
    border-radius: 12px;
    margin-right: 8px;
    ${media.md} {
      padding: 12px 8px;
    }
  `,
  value: () => css`
    padding: 0 8px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    cursor: pointer;

    ${media.md} {
      padding: 0 4px;
      svg {
        display: none;
      }
    }

    span {
      margin-left: 4px;
    }
  `,
  refresh: () => css`
    padding: 0 4px;
    color: ${theme.colors.secondary[900]};
    cursor: pointer;
  `,

  loading: () => css`
    span {
      animation: ${spin} 1s ease infinite;
    }
  `,

  contentPopup: css`
    width: 320px;
    h3 {
      color: ${theme.colors.white};
      font-weight: 500;
      font-size: 14px;
      padding: 12px 12px 0 12px;
    }

    .list-currency {
      display: flex;
      flex-direction: column;
      width: 100%;

      .item-currency {
        display: flex;
        flex-direction: row;
        align-items: center;
        padding: 12px;
        width: 100%;
        cursor: pointer;
        margin-top: 12px;

        &:hover {
          background-color: ${theme.colors.primary[300]};
          .amount {
            color: ${theme.colors.secondary[700]};
          }
        }

        .name {
          flex: 1;
          margin: 0 8px;
        }

        .amount {
          color: ${theme.colors.secondary[700]};
        }
      }
    }
  `
};
