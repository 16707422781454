import { css } from "@emotion/react";

export const InputPhoneNumberStyle = {
  inputContainer: () => css`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    background: #101520;
    border: 1px solid rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    overflow: hidden;
  `,
  inputPhoneNumber: () => css`
    flex: 1;
    height: 44px;
    padding-left: 12px;

    .PhoneInputInput {
      height: 44px;
      border: 0px;
      outline: none;
      padding-left: 16px;
      background-color: #101520;
      color: #ffffff80;
    }
    input::placeholder {
      color: #ffffff50 !important;
    }
    .ant-input-suffix {
      svg {
        color: #ffffff80;
      }
    }
    .PhoneInputCountrySelectArrow {
      margin-left: 8px !important;
    }
  `
};
