import { createSlice } from "@reduxjs/toolkit";
import { ApplicationRootState } from "types";
import { MemberStateType } from "types/member";

import { extraReducers } from "./member.extraReducer";
export const initialState: MemberStateType = {
  isLoading: false,
  dataMemberVip: {
    data: {
      total_level: 0,
      current_level: 0,
      next_level: {
        level: 0,
        turnover: "",
        current_turnover: "",
        turnover_percentage: ""
      },
      benefit: {
        birthday_bonus: "",
        upgrade_reward: ""
      },
      rebate: {
        LC: "",
        SL: "",
        SB: "",
        FH: "",
        ES: "",
        BG: "",
        LT: "",
        KN: "",
        CF: "",
        OT: ""
      }
    }
  }
};

const memberSlice = createSlice({
  name: "member",
  initialState,
  reducers: {
    //
  },
  extraReducers
});

export const selectLoadingDataMemberVip = (state: ApplicationRootState) =>
  state.member.isLoading;

export const selectDataMemberVip = (state: ApplicationRootState) =>
  state.member.dataMemberVip;

export default memberSlice.reducer;
