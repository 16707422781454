import ACEW from "assets/images/game/Slot/slots_ACEW.png";
import JILI from "assets/images/game/Slot/slots_JILI.png";
import MG from "assets/images/game/Slot/slots_MG.png";
import PT from "assets/images/game/Slot/slots_PT.png";
import { SubGameType } from "types/game";

export const listGameSlots: SubGameType[] = [
  {
    game_code: "0",
    name: "Lobby",
    provider_code: "MG",
    type: "SL",
    featured: "NO",
    new_arrive: "NO",
    picture: MG
  },
  {
    game_code: "0",
    name: "Lobby",
    provider_code: "PT",
    type: "SL",
    featured: "NO",
    new_arrive: "NO",
    picture: PT
  },
  {
    game_code: "0",
    name: "Lobby",
    provider_code: "JILI",
    type: "SL",
    featured: "NO",
    new_arrive: "NO",
    picture: JILI
  },
  {
    game_code: "0",
    name: "Lobby",
    provider_code: "ACEW",
    type: "SL",
    featured: "NO",
    new_arrive: "NO",
    picture: ACEW
  }
];
