import { ActionReducerMapBuilder, PayloadAction } from "@reduxjs/toolkit";
import { CategoryDetailsResponseType, SubGameResponse } from "api/game/type";
import { ResponseCode } from "constants/response";
import { NoInfer } from "react-redux";
import { GameStateType } from "types/game";

import { getGameList, getSubGameList } from "./game.thunks";

export const extraReducers = (
  builder: ActionReducerMapBuilder<NoInfer<GameStateType>>
) => {
  builder
    .addCase(getSubGameList.pending, (state: GameStateType) => {
      state.isLoading = true;
    })
    .addCase(
      getSubGameList.fulfilled,
      (state: GameStateType, action: PayloadAction<SubGameResponse>) => {
        state.isLoading = false;

        if (action.payload.status !== ResponseCode.SUCCESS) return;

        if (action.payload.lobby && action.payload.game_type) {
          state.dataCategoryGameFilter = [...action.payload.data];
          return;
        }

        if (action.payload.lobby) {
          state.dataCategoryGameAll = [...action.payload.data];
          return;
        }

        if (action.payload.flag === "HOT_GAME") {
          state.dataHotGame.data = [...action.payload.data];
          return;
        }

        if (action.payload.isAllGame) {
          state.dataAllSubGame.data = [...action.payload.data];
          return;
        }

        if (action.payload.currentPage > 1) {
          state.dataSubGame.data = [
            ...state.dataSubGame.data,
            ...action.payload.data
          ];
          return;
        }

        state.dataSubGame.data = [...action.payload.data];
        state.dataSubGame.totalCount = action.payload.totalCount;
        state.dataSubGame.totalPages = action.payload.totalPages;
      }
    );

  builder
    .addCase(getGameList.pending, (state: GameStateType) => {
      state.isLoading = true;
    })
    .addCase(
      getGameList.fulfilled,
      (
        state: GameStateType,
        action: PayloadAction<CategoryDetailsResponseType>
      ) => {
        state.isLoading = false;

        if (action.payload.status !== ResponseCode.SUCCESS) return;

        state.dataCategoryDetails = [...action.payload.data];
      }
    );
};
