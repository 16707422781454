import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import moment from "moment";
import { ApplicationRootState } from "types";
import { BettingRecordStateType } from "types/bettingrecord";

import { extraReducers } from "./bettingrecord.extraReducers";

export const initialState: BettingRecordStateType = {
  data: [],
  total: 0,
  isLoading: false,
  page: 1,
  startDate: moment().format("YYYY-MM-DD").toString()
};

const bettingRecordSlice = createSlice({
  name: "bettingrecord",
  initialState,
  reducers: {
    updateCurrentPageBettingRecord(
      state: BettingRecordStateType,
      { payload }: PayloadAction<number>
    ) {
      state.page = payload;
    },
    updateStartDateBetting(
      state: BettingRecordStateType,
      { payload }: PayloadAction<string>
    ) {
      state.startDate = payload;
    },

    resetBettingRecord: () => initialState
  },
  extraReducers
});

export const selectDataBettingRecord = (state: ApplicationRootState) =>
  state.bettingRecord.data;

export const selectIsLoadingBettingRecord = (state: ApplicationRootState) =>
  state.bettingRecord.isLoading;

export const selectTotalBettingRecord = (state: ApplicationRootState) =>
  state.bettingRecord.total;

export const selectPageBettingRecord = (state: ApplicationRootState) =>
  state.bettingRecord.page;

export const selectStartDateBettingRecord = (state: ApplicationRootState) =>
  state.bettingRecord.startDate;

export const {
  updateCurrentPageBettingRecord,
  updateStartDateBetting,
  resetBettingRecord
} = bettingRecordSlice.actions;

export default bettingRecordSlice.reducer;
