import { css } from "@emotion/react";
import { media } from "styles/breakpoints";

export const WithdrawStyle = {
  index: () => css`
    color: white;
    margin-top: 25px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-content: flex-start;

    ${media.md} {
      padding: 0 16px;
    }
  `,

  title: () => css`
    margin-bottom: 10px;
    color: #9196af;
    font-size: medium;
  `,

  bank: {
    bankList: () => css`
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      gap: 20px;
    `,

    bankItem: (playerBankId: string, selectedPlayerBankId: string) => css`
      border-radius: 10px;
      background-color: #3d4668;
      height: 90px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
      padding-left: 8px;
      cursor: pointer;
      border: ${playerBankId === selectedPlayerBankId
        ? "1px solid rgba(255, 179, 26, 0.8)"
        : "1px solid transparent"};
    `,

    bankText: () => css`
      margin-left: 8px;
      font-size: 14px;
      font-style: normal;
      font-weight: 300;
      line-height: 22px;
      color: white;
    `,

    bankAdd: () => css`
      border-radius: 10px;
      border: 2px dashed #313c74;
      height: 90px;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
    `,

    bankTextAdd: () => css`
      margin-left: 8px;
      font-size: 14px;
      font-style: normal;
      font-weight: 300;
      line-height: 22px;
      color: #9196af;
    `
  }
};
