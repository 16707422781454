/** @jsxImportSource @emotion/react */

import { Modal } from "antd";
import { dataLanguage } from "constants/languages";
import { useAppDispatch } from "hooks/app-hook";
import { useTranslation } from "react-i18next";
import { setLang } from "store/languageStore";
import { setOpenModal } from "store/modal";
import { ModalTypeValue } from "types/modal";

import WrapperForm from "../WrapperForm";
import { LoginModalStyle } from "./index.style";

function LanguageModal() {
  const { i18n } = useTranslation("common");
  const dispatch = useAppDispatch();
  const handleChangeLanguage = (lang: string) => {
    dispatch(setOpenModal(ModalTypeValue.Close));
    dispatch(setLang(lang));
    i18n.changeLanguage(lang);
  };

  return (
    <Modal
      open={true}
      closable={false}
      forceRender
      onCancel={() => dispatch(setOpenModal(ModalTypeValue.Close))}
      css={LoginModalStyle.modalContainer}
      footer={<></>}
      centered
      focusTriggerAfterClose={false}
      width={300}
    >
      <WrapperForm
        title={"Language"}
        formType={ModalTypeValue.Login}
        body={
          <div css={LoginModalStyle.formContainer}>
            {dataLanguage.map((item, index) => {
              return (
                <div
                  className="lang-row"
                  data-sort="1"
                  data-lang={item.id.toUpperCase()}
                  key={index}
                  onClick={() => handleChangeLanguage(item.id)}
                >
                  <div>
                    <img
                      src={item.img}
                      height={20}
                      alt={item.name}
                      className="flag"
                    />
                  </div>
                  <div>{item.name}</div>
                </div>
              );
            })}
          </div>
        }
      />
    </Modal>
  );
}

export default LanguageModal;
