import axiosRequest from "api";
import { payloadHelper } from "helpers/payload-helper";
import {
  DepositOfflineSubmissionFormNew,
  DepositOnlineSubmissionFormNew
} from "types/deposit";

import {
  DepositFormSettingResponse,
  DepositOfflineSubmissionForm,
  DepositOnlineSubmissionForm,
  DepositResponse,
  DepositSubmissionFormType
} from "./type";

export const DepositApi = {
  getDepositForm: (username: string) =>
    axiosRequest.post<DepositFormSettingResponse>(
      "",
      payloadHelper("DepositForm", username, {
        username
      })
    ),
  depositSubmission: (
    typename: string,
    payload:
      | DepositOnlineSubmissionForm
      | DepositOfflineSubmissionForm
      | DepositSubmissionFormType
  ) =>
    axiosRequest.post<DepositResponse>(
      "/",
      payloadHelper("Deposit", typename, payload)
    ),
  depositSubmissionOffline: (
    typename: string,
    payload: DepositOfflineSubmissionFormNew
  ) =>
    axiosRequest.post<DepositResponse>(
      "/",
      payloadHelper("Deposit", typename, payload)
    ),
  depositSubmissionOnline: (
    typename: string,
    payload: DepositOnlineSubmissionFormNew
  ) =>
    axiosRequest.post<any>(
      "/",
      payloadHelper("GatewayDeposit", typename, payload)
    )
};
