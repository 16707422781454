import { css } from "@emotion/react";
import { media } from "styles/breakpoints";

export const AmountStepStyle = {
  index: () => css`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;

    .heading-title {
      color: #9196af;
      font-size: 16px;
      font-weight: 500;
    }

    .text-warning {
      color: #ffc107;
      font-size: 14px;
      margin-top: 10px;
      max-width: 300px;
      display: block;
      margin-bottom: 20px;

      &.small {
        font-style: italic;
        font-size: 12px;
      }

      ${media.md} {
        max-width: 100%;
      }
    }
  `,
  formControl: css`
    width: 65%;
    margin-bottom: 20px;

    ${media.md} {
      width: 100%;
    }
  `,
  title: () => css`
    margin-bottom: 20px;
    color: #9196af;
    font-size: medium;
  `
};
