import { css } from "@emotion/react";
import { theme } from "styles/theme";

export const ButtonFunctionStyle = {
  wrapper: css`
    padding: 13px 16px;

    border-radius: 10px;
    border: 1px solid #313c74;
    background: rgba(28, 36, 51, 0.5);

    display: flex;
    align-items: center;
    justify-content: center;
    transition: 0.25s;
    &:active {
      text-decoration: none !important;
    }
    &:hover {
      transition: 0.25s;
      border-color: ${theme.colors.secondary[900]};
    }

    span {
      margin-left: 10px;

      color: #fff;
      text-align: center;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 16px;
      text-decoration: none;
    }
  `
};
