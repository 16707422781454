import { css } from "@emotion/react";
import { media } from "styles/breakpoints";
import { theme } from "styles/theme";

export const DepositStyle = {
  index: () => css`
    background: rgba(16, 21, 32, 0.8);
    border-radius: 8px;
    min-height: 65vh;

    ${media.md} {
      min-height: 75vh;
    }
  `,
  content: css``,
  boxOptions: css`
    display: flex;
    flex-direction: column;
    padding: 12px;

    button {
      min-width: 300px;
    }

    ${media.md} {
      button {
        width: 50%;
      }
    }
    ${media.sm} {
      button {
        width: 100%;
      }
    }
    h3 {
      color: ${theme.colors.primary[100]};
      font-size: 16px;
      font-weight: 600;
      line-height: 22px;
    }
  `,
  listOptions: css`
    margin-top: 13px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-wrap: wrap;
  `,
  itemOptions: (isActive: boolean) => css`
    padding: 11px 9px;
    border-radius: 10px;
    border: 1px solid
      ${isActive ? `rgba(255, 179, 26, 0.8)` : theme.colors.primary[500]};
    background: rgba(61, 70, 104, 0.3);
    cursor: pointer;
    margin-right: 8px;
    min-width: 120px;
    margin-bottom: 6px;
    width: 20%;

    ${media.sm} {
      width: calc(33% - 8px);
      margin-bottom: 8px;
      min-width: unset;
    }
    .info {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      img {
        width: 37px;
        height: 37px;
      }

      span {
        color: ${isActive ? theme.colors.secondary[700] : theme.colors.white};
        text-align: center;
        font-size: 13px;
        font-weight: 400;
        line-height: 16px;
        margin-top: 6px;
        white-space: nowrap;
      }
    }
  `
};
