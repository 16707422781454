/** @jsxImportSource @emotion/react */

import { Form, Select, SelectProps } from "antd";
import { ValidateStatus } from "antd/es/form/FormItem";
import { Control, Controller, FieldValues } from "react-hook-form";

import { SelectCustomStyle } from "./index.style";

interface SelectCustomProps extends SelectProps {
  dropdownBackground?: string;
  name?: string;
  control?: Control<FieldValues | any>;
  defaultValue?: any;
  error?: string;
  validate_status?: ValidateStatus;
}

const SelectCustom = (props: SelectCustomProps) => {
  const {
    dropdownBackground,
    name = "",
    control,
    defaultValue,
    error,
    validate_status
  } = props;

  const dropdownStyle = {
    padding: "10px",
    backgroundColor: dropdownBackground ? dropdownBackground : "white"
  };
  return (
    <div css={SelectCustomStyle.self}>
      {control ? (
        <Form.Item help={error} validateStatus={validate_status}>
          <Controller
            name={name}
            control={control}
            render={({
              field: { onChange: _onChange, value },
              formState: { errors }
            }) => (
              <Select
                {...props}
                dropdownStyle={dropdownStyle}
                onChange={_onChange}
                defaultValue={defaultValue}
                value={value}
              />
            )}
          />
        </Form.Item>
      ) : (
        <Select
          {...props}
          dropdownStyle={dropdownStyle}
          defaultValue={defaultValue}
        />
      )}
    </div>
  );
};

export default SelectCustom;
