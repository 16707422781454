import { ColumnsType } from "antd/es/table";
import { OrderStatus } from "enums/orders";
import moment from "moment";
import { useTranslation } from "react-i18next";
export const formatDate = `YYYY-MM-DD`;
export const pageSize = 10;
export interface Pages {
  currentPage: number;
  totalPages: number;
  totalCount: number;
}

export const useColumns = () => {
  const { t } = useTranslation("transaction");
  const depositColumns: ColumnsType<any> = [
    {
      title: t("depositCol.id"),
      dataIndex: "transaction_code",
      width: "fit-content",
      ellipsis: true,
      className: "word-break",
      key: "transaction_code"
    },
    {
      title: t("depositCol.Amount"),
      dataIndex: "amount",
      key: "amount"
    },
    {
      title: t("depositCol.Date"),
      dataIndex: "update_date",
      key: "update_date",
      render(value) {
        return <span>{moment(value).format(formatDate)}</span>;
      }
    },
    {
      title: t("depositCol.Status"),
      dataIndex: "status",
      key: "status",
      render(value) {
        const statusName = formatStatus(value);
        return (
          <span style={{ color: handleColorStatus(value) }}>{statusName}</span>
        );
      }
    }
  ];

  const rebateColumns: ColumnsType<any> = [
    {
      title: t("rebateCol.Promotion"),
      dataIndex: "promotion",
      key: "promotion"
    },
    {
      title: t("rebateCol.Reward"),
      dataIndex: "reward",
      key: "reward",
      className: "center"
    },
    {
      title: t("rebateCol.Turnover"),
      dataIndex: "current_turnover",
      key: "current_turnover",
      className: "center"
    },
    {
      title: t("rebateCol.TotalTurnover"),
      dataIndex: "total_turnover",
      className: "center",
      key: "total_turnover"
    },
    {
      title: t("rebateCol.Date"),
      dataIndex: "create_date",
      className: "center",
      key: "create_date",
      render(value) {
        return <span>{moment(value).format(formatDate)}</span>;
      }
    },
    {
      title: t("rebateCol.Status"),
      dataIndex: "status",
      className: "center",
      render(value) {
        const statusName = formatStatus(value);
        return (
          <span style={{ color: handleColorStatus(value) }}>{statusName}</span>
        );
      }
    }
  ];

  const formatStatus = (value: number) => {
    switch (Number(value)) {
      case OrderStatus.Approve:
        return t("Approve");
      case OrderStatus.Pending:
        return t("Pending");
      case OrderStatus.Processing:
        return t("Processing");
      case OrderStatus.Reject:
        return t("Reject");
      default:
        return;
    }
  };

  return { depositColumns, rebateColumns, formatStatus };
};

export const handleColorStatus = (key: OrderStatus) => {
  switch (Number(key)) {
    case OrderStatus.Approve:
      return "#10AC02";
    case OrderStatus.Pending:
      return "#EDA10F";
    case OrderStatus.Processing:
      return "#0477FD";
    case OrderStatus.Reject:
      return "#F1212A";
  }
};
