import { HeaderUser } from "components/ui/user/HeaderUser";
import { UserWrapperPage } from "components/ui/user/UserWrapperPage";
import { useAppDispatch, useAppSelector } from "hooks/app-hook";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { selectUsername } from "store/auth";
import { getMemberVip } from "store/member/member.thunk";

import Benefits from "./Benefits";
import RankStatus from "./RankStatus";
import VipConditions from "./VipConditions";

const MemberVipPage = () => {
  const { t } = useTranslation(["common", "vip"]);
  const dispatch = useAppDispatch();
  const username = useAppSelector(selectUsername);

  useEffect(() => {
    if (!username) return;

    dispatch(getMemberVip({ username }));
  }, [username]);

  return (
    <UserWrapperPage hasMaxWidth>
      <HeaderUser title={t("vip:myvip")} />
      <RankStatus />
      <Benefits />
      <VipConditions />
    </UserWrapperPage>
  );
};

export default MemberVipPage;
