/** @jsxImportSource @emotion/react */
import { message } from "antd";
import { ReactComponent as IconCopy } from "assets/icons/icon-copy.svg";
import LogoHeader from "assets/images/logo-main-989.webp";
import ImageAnimeChar from "assets/images/profile/anime-char.png";
import ImageCoverImage from "assets/images/profile/image-cover.png";
import ButtonFunction from "components/ui/ButtonFunction";
import CurrencyItemProfile from "components/ui/CurrencyItemProfile";
import HelmetCustom from "components/ui/Helmet";
import Loading from "components/ui/Loading";
import SideBarProfile from "components/ui/SideBarProfile";
import { LinkDownloadFileApk } from "constants/apk";
import { ArrowNextIcon, ImageAvatarUser } from "constants/config";
import { FUNCTION_LIST, TypeFunctionList } from "constants/function-list";
import { listFunctionBtn } from "constants/list-btn-function";
import { LIST_CURRENCY } from "dummy/list-currency";
import { useAppDispatch, useAppSelector } from "hooks/app-hook";
import { useVerify } from "hooks/useVerify";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { setOpenModal } from "store/modal";
import { selectProfile } from "store/user";
import { ModalTypeValue } from "types/modal";

import { ProfileInfoStyle } from "./style.index";
import useChangeHeaderMobile from "./useChangeHeaderMobile";

const ProfileInfoPage = () => {
  const { profile } = useAppSelector((state) => state.user);
  const { t } = useTranslation(["common", "profile"]);

  const { isLoading } = useVerify();
  const dispatch = useAppDispatch();
  const dataProfile = useAppSelector(selectProfile);
  const navigate = useNavigate();
  useChangeHeaderMobile();

  const [messageApi, contextHolder] = message.useMessage();

  const copyToClipboard = (txt: string) => {
    navigator.clipboard.writeText(txt);
    messageApi.success(t("profile:copy_success"));
  };

  const filterButtonsTradings = (upline: string) => {
    return upline === "cashline"
      ? listFunctionBtn
      : listFunctionBtn.filter((btn) => btn.key !== 1 && btn.key !== 2);
  };

  if (isLoading) return <Loading height={500} width={"100%"} center />;
  return (
    <div css={ProfileInfoStyle.index}>
      {contextHolder}
      <HelmetCustom title={t("profile:title")} />
      <div id="header-profile-page" css={ProfileInfoStyle.tabName}>
        <div className="avatar">
          <img src={LogoHeader} alt={t("profile:logo_alt")} />
        </div>
        <div className="titlePage">{t("profile:title_page")}</div>
      </div>
      <div css={ProfileInfoStyle.wrapper}>
        <div css={ProfileInfoStyle.mainContent}>
          <div css={ProfileInfoStyle.header.index}>
            <div css={ProfileInfoStyle.header.coverImage}>
              <img src={ImageCoverImage} alt={t("profile:cover_image_alt")} />
            </div>
            <div css={ProfileInfoStyle.header.bottom}>
              <div css={ProfileInfoStyle.header.contentBottom.index}>
                <div css={ProfileInfoStyle.header.contentBottom.avt}>
                  <img src={ImageAvatarUser} alt={t("profile:avatar_alt")} />
                </div>
                <div css={ProfileInfoStyle.header.contentBottom.information}>
                  <span>{dataProfile?.username}</span>
                  <IconCopy
                    onClick={() => copyToClipboard(dataProfile?.username || "")}
                    style={{
                      cursor: "pointer"
                    }}
                  />
                </div>
              </div>
            </div>
            <img
              css={ProfileInfoStyle.header.animeChar}
              src={ImageAnimeChar}
              alt={t("profile:anime_char_alt")}
            />
          </div>
          <div css={ProfileInfoStyle.bottom.index}>
            <div css={ProfileInfoStyle.bottom.accountBalance}>
              <div css={ProfileInfoStyle.bottom.title}>
                <span>{t("profile:account_balance")}</span>
              </div>
              {LIST_CURRENCY.map((itemCurrency, index) => (
                <CurrencyItemProfile key={index} {...itemCurrency} />
              ))}
            </div>
            <div css={ProfileInfoStyle.bottom.menuUser}>
              <div css={ProfileInfoStyle.bottom.title}>
                <span>{t("profile:frequently_used")}</span>
              </div>
              <div css={ProfileInfoStyle.bottom.listFunctionBtn}>
                {filterButtonsTradings(String(profile?.upline)).map(
                  (itemBtn) => (
                    <ButtonFunction {...itemBtn} key={itemBtn.key} />
                  )
                )}
              </div>
              <div css={ProfileInfoStyle.bottom.title}>
                {t("profile:function_list")}
              </div>
              <div css={ProfileInfoStyle.contentFunction.index}>
                {FUNCTION_LIST.map((data: TypeFunctionList) => {
                  if (data.key === 2 && profile?.upline !== "cashline") return;
                  return (
                    <div
                      key={data.key}
                      css={ProfileInfoStyle.contentFunction.functionItem}
                    >
                      <div css={ProfileInfoStyle.contentFunction.header}>
                        {t(`${data.title}`)}
                      </div>
                      <div css={ProfileInfoStyle.contentFunction.bottom}>
                        {data.content.map((itemContent, index) => (
                          <div
                            key={index}
                            css={ProfileInfoStyle.contentFunction.wrapperItem}
                            onClick={() => {
                              if (itemContent.id === "change-password") {
                                dispatch(
                                  setOpenModal(ModalTypeValue.ChangePassword)
                                );
                                return;
                              }

                              if (itemContent.name === "Download APP") {
                                window.open(LinkDownloadFileApk, "_blank");

                                return;
                              }
                              navigate(`${itemContent.id}`);
                            }}
                          >
                            <div
                              css={ProfileInfoStyle.contentFunction.funcItem}
                            >
                              <itemContent.image />
                              <span>{t(`${itemContent.name}`)}</span>
                            </div>
                            <ArrowNextIcon className="arrow-svg" />
                          </div>
                        ))}
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
        <SideBarProfile />
      </div>
    </div>
  );
};

export default ProfileInfoPage;
