import { useAppDispatch, useAppSelector } from "hooks/app-hook";
import { useEffect } from "react";
import { Cookies } from "react-cookie";
import { useParams, useSearchParams } from "react-router-dom";
import { selectOpenModal, setOpenModal } from "store/modal";
import { ModalTypeValue } from "types/modal";

import ChangePasswordModal from "./ChangePasswordModal";
import ForgetPasswordModal from "./ForgetPasswordModal";
import LanguageModal from "./LanguageModal";
import LoginModal from "./LoginModal";
import RegisterModal from "./RegisterModal";

function Modal() {
  const cookies = new Cookies();

  const { referCodeAgent } = useParams();

  const referCodeAgentCookie = cookies.get("referCodeAgent");

  const [params] = useSearchParams();
  const code = params.get("code");
  const modalOpen = useAppSelector(selectOpenModal);
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (code || referCodeAgent) {
      dispatch(setOpenModal(ModalTypeValue.Register));
    }

    if (referCodeAgent) {
      cookies.set("referCodeAgent", referCodeAgent, {
        maxAge: 60 * 60 * 24 * 7
      });
    }
  }, []);

  return (
    <>
      {modalOpen === ModalTypeValue.Login && <LoginModal />}
      {modalOpen === ModalTypeValue.ChangeLanguage && <LanguageModal />}
      {modalOpen === ModalTypeValue.Register && (
        <RegisterModal code={code || referCodeAgentCookie || ""} />
      )}
      {modalOpen === ModalTypeValue.ForgetPassword && <ForgetPasswordModal />}
      {modalOpen === ModalTypeValue.ChangePassword && <ChangePasswordModal />}
    </>
  );
}

export default Modal;
