import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ApplicationRootState } from "types";

export type LanguageType = {
  lang: string;
};

export const initialState: LanguageType = {
  lang: "en"
};

const languageSlice = createSlice({
  name: "language",
  initialState,
  reducers: {
    setLang: (state: LanguageType, action: PayloadAction<string>) => {
      state.lang = action.payload;
    }
  }
});
export const selectedLang = (state: ApplicationRootState) => {
  return state.language.lang;
};
export const { setLang } = languageSlice.actions;

export default languageSlice.reducer;
