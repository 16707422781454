/** @jsxImportSource @emotion/react */

import { DatePicker, DatePickerProps, Form } from "antd";
import { ValidateStatus } from "antd/es/form/FormItem";
import { Control, Controller, FieldValues } from "react-hook-form";

import { DatePickerCustomStyle } from "./index.style";

type DatePickerCustomProps = DatePickerProps & {
  name?: string;
  control?: Control<FieldValues | any>;
  defaultValue?: any;
  error?: string;
  validate_status?: ValidateStatus;
  format?: string;
};

const DatePickerCustom = (props: DatePickerCustomProps) => {
  const {
    name = "",
    control,
    defaultValue,
    error,
    validate_status,
    format = "DD/MM/YYYY"
  } = props;
  return (
    <div css={DatePickerCustomStyle.self}>
      {control ? (
        <Form.Item help={error} validateStatus={validate_status}>
          <Controller
            name={name}
            control={control}
            render={({
              field: { onChange: _onChange },
              formState: { errors }
            }) => (
              <DatePicker
                popupClassName="date-picker-custom"
                defaultValue={defaultValue}
                onChange={(date, dateString) => _onChange(dateString)}
                format={format}
                {...props}
              />
            )}
          />
        </Form.Item>
      ) : (
        <DatePicker popupClassName="date-picker-custom" {...props} />
      )}
    </div>
  );
};

export default DatePickerCustom;
