import { createSlice } from "@reduxjs/toolkit";
import { ApplicationRootState } from "types";
import { BannerStateType } from "types/banner";

import { extraReducers } from "./banner.extraReducers";

const initialState: BannerStateType = {
  isLoading: false,
  banners: []
};

const bannerSlice = createSlice({
  name: "banner",
  initialState,
  reducers: {
    resetBanner: () => initialState
  },
  extraReducers
});

export const selectIsLoading = (state: ApplicationRootState) =>
  state.banner.isLoading;
export const selectBanners = (state: ApplicationRootState) =>
  state.banner.banners;

export const { resetBanner } = bannerSlice.actions;

export default bannerSlice.reducer;
