const profile = "/profile";

const PATH = {
  home: "/",
  referCodeAgent: "/join/index/:referCodeAgent",
  lottery: "/lottery-home",
  categoryGamePage: "/:categoryName",
  subGamePage: "/:categoryName/:providerId",
  myCollectionPage: "/my-collection",
  playRecordPage: "/play-record",
  promotionPage: "/promotion",
  promotionDetailPage: "/promotion-detail/:slug/:id",
  profile: {
    home: profile,
    myBet: profile + "/bet",
    transaction: profile + "/transaction",
    trading: profile + "/trading",
    friendSharing: profile + "/friend-sharing",
    notificationCenter: profile + "/notification",
    mybet: profile + "/bet",
    myVip: profile + "/vip",
    rebate: profile + "/percentage"
  }
};
export default PATH;
