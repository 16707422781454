/** @jsxImportSource @emotion/react */
import { yupResolver } from "@hookform/resolvers/yup";
import { Button, Form, Modal } from "antd";
import { RegisterFormType } from "api/auth/type";
import InputCustom from "components/base/InputCustom";
import InputPassword from "components/base/InputPassword";
import InputPhoneNumber from "components/base/InputPhoneNumber";
import { REGEX_PASSWORD, REGEX_USERNAME } from "constants/regex";
import { ResponseCode } from "constants/response";
import { useAppDispatch, useAppSelector } from "hooks/app-hook";
import { useEffect } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useOutletContext } from "react-router-dom";
import { selectIsLoading } from "store/auth";
import { registerMemberDetail } from "store/auth/auth.thunk";
import { setOpenModal } from "store/modal";
import { outletContextType } from "types";
import { ApiResponse } from "types/api";
import { ModalTypeValue } from "types/modal";
import * as yup from "yup";

import WrapperForm from "../WrapperForm";
import { RegisterModalStyle } from "./index.style";

interface props {
  code: string | null;
}
function RegisterModal({ code }: props) {
  const { openNotification } = useOutletContext<outletContextType>();
  const { t } = useTranslation(["common", "register"]);

  const schema = yup
    .object({
      username: yup
        .string()
        .trim()
        .matches(REGEX_USERNAME, t("register:username_validation"))
        .required(t("register:username_required")),
      mobile: yup.string().trim().required(t("register:mobile_required")),
      email: yup.string().trim().email().required(t("register:email_required")),
      password: yup
        .string()
        .trim()
        .matches(REGEX_PASSWORD, t("register:password_validation"))
        .required(t("register:password_required")),
      confirm_password: yup
        .string()
        .trim()
        .matches(REGEX_PASSWORD, t("register:password_validation"))
        .required(t("register:password_required"))
    })
    .required();
  const dispatch = useAppDispatch();

  const isLoading = useAppSelector(selectIsLoading);

  const {
    handleSubmit,
    control,
    setValue,
    formState: { errors, isValid }
  } = useForm<RegisterFormType>({
    resolver: yupResolver<any>(schema),
    mode: "all"
  });

  useEffect(() => {
    if (code) {
      setValue("referral", code);
    }
  }, []);

  const onSubmit = async (data: RegisterFormType) => {
    const dataForm = {
      ...data,
      mobile: data.mobile?.replace("+", ""),
      fullname: data.username
    };
    const res = await dispatch(registerMemberDetail(dataForm));

    const resData = res.payload as ApiResponse;

    if (resData.status !== ResponseCode.SUCCESS) {
      openNotification("error", res.payload as string);
      return;
    }

    dispatch(setOpenModal(ModalTypeValue.Close));
  };

  return (
    <Modal
      open={true}
      closable={false}
      onCancel={() => dispatch(setOpenModal(ModalTypeValue.Close))}
      css={RegisterModalStyle.modalContainer}
      footer={<></>}
      centered
    >
      <WrapperForm
        title={t("register:title")}
        formType={ModalTypeValue.Register}
        body={
          <div css={RegisterModalStyle.formContainer}>
            <Form
              css={RegisterModalStyle.formContainer}
              onSubmitCapture={handleSubmit(onSubmit)}
              autoComplete="off"
            >
              <div css={RegisterModalStyle.inputControl}>
                <label>{t("register:username_label")}</label>
                <InputCustom
                  disabled={isLoading}
                  placeholder={t("register:username_placeholder")}
                  name={"username"}
                  margin_bottom="0px"
                  control={control}
                  errors={errors.username?.message}
                  validate_status={errors.username ? "error" : "success"}
                />
              </div>
              <div css={RegisterModalStyle.inputControl}>
                <label>{t("register:password_label")}</label>
                <InputPassword
                  disabled={isLoading}
                  placeholder={t("register:password_placeholder")}
                  name={"password"}
                  control={control}
                  margin_bottom="0px"
                  error={errors.password?.message}
                  validate_status={errors.password ? "error" : "success"}
                />
              </div>
              <div css={RegisterModalStyle.inputControl}>
                <label>{t("register:confirm_password_label")}</label>
                <InputPassword
                  disabled={isLoading}
                  placeholder={t("register:confirm_password_placeholder")}
                  name={"confirm_password"}
                  control={control}
                  margin_bottom="0px"
                  autoComplete="new-password"
                  error={errors.confirm_password?.message}
                  validate_status={
                    errors.confirm_password ? "error" : "success"
                  }
                />
              </div>
              <div css={RegisterModalStyle.inputControl}>
                <label>{t("register:email_label")}</label>
                <InputCustom
                  disabled={isLoading}
                  placeholder={t("register:email_placeholder")}
                  name={"email"}
                  margin_bottom="0px"
                  control={control}
                  errors={errors.email?.message}
                  validate_status={errors.email ? "error" : "success"}
                />
              </div>
              <div css={RegisterModalStyle.inputControl}>
                <label>{t("register:mobile_label")}</label>
                <InputPhoneNumber
                  disabled={isLoading}
                  name={"mobile"}
                  control={control}
                  margin_bottom="0px"
                  error={errors.mobile?.message}
                  validate_status={errors.mobile ? "error" : "success"}
                  onChange={(val) => {
                    //
                  }}
                />
              </div>
              <div css={RegisterModalStyle.inputControl}>
                <label>{t("register:referral_label")}</label>
                <InputCustom
                  disabled={isLoading || !!code}
                  placeholder={t("register:referral_placeholder")}
                  name={"referral"}
                  margin_bottom="0px"
                  control={control}
                  errors={errors.referral?.message}
                  validate_status={errors.referral ? "error" : "success"}
                />
              </div>

              <Button
                disabled={!isValid}
                loading={isLoading}
                css={[
                  RegisterModalStyle.buttonSubmit,
                  isValid && RegisterModalStyle.buttonActive
                ]}
                htmlType="submit"
              >
                {t("register:submit_button")}
              </Button>
            </Form>
          </div>
        }
        footer={
          <div css={RegisterModalStyle.footer}>
            <p>
              {t("register:already_account")}{" "}
              <span
                onClick={() => dispatch(setOpenModal(ModalTypeValue.Login))}
              >
                {t("register:go_to_login")}
              </span>
            </p>
          </div>
        }
      />
    </Modal>
  );
}

export default RegisterModal;
