import { css } from "@emotion/react";
import { media } from "styles/breakpoints";

export const PromotionPageStyle = {
  wrapper: css`
    max-width: 1024px;
    margin: 20px auto;
    ${media.lg} {
      max-width: 980px;
      margin: 20px 70px;
    }
    ${media.md} {
      max-width: unset;
      margin: 10px 5px;
    }
  `,
  mainContent: css`
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 16px;

    margin-top: 25px;

    ${media.lg} {
      grid-template-columns: repeat(2, 1fr);
    }
    ${media.md} {
      grid-template-columns: repeat(2, 1fr);
      gap: 6px;
    }
  `
};
