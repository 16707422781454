import { css, keyframes } from "@emotion/react";
import { media } from "styles/breakpoints";
import { theme } from "styles/theme";

const animateArrow = keyframes`
    0% {
        opacity: 0;
        transform: rotate(45deg) translate(-10px, -10px);
    }
    50% {
        opacity: 1;
    }
    100% {
        opacity: 0;
        transform: rotate(45deg) translate(10px, 10px);
    }
`;

export const CardPromotionStyle = {
  wrapper: css`
    display: flex;
    flex-direction: column;
    border-radius: 10px;
    position: relative;
    img {
      width: 100%;
      max-height: 300px;
      border-top-right-radius: 10px;
      border-top-left-radius: 10px;
      height: 100%;
      object-fit: cover;
    }
  `,
  textBox: css`
    background-color: #1d2841;
    border-bottom-right-radius: 10px;
    border-bottom-left-radius: 10px;

    padding: 15px;
    ${media.sm} {
      padding: 5px;
    }
    h4 {
      color: #fff;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 24px;
      margin-bottom: 10px;

      display: -webkit-box;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;
      overflow: hidden;

      ${media.sm} {
        font-size: 12px;
        line-height: unset;
        margin-bottom: 5px;
        max-width: 196px;

        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
        -webkit-line-clamp: 1;
      }
    }
    p {
      border-top: 1px solid ${theme.colors.primary[600]};
      border-bottom: 1px solid ${theme.colors.primary[600]};
      padding: 10px 0;

      display: flex;
      align-items: center;
      justify-content: space-between;

      color: #9196af;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 22px;

      ${media.sm} {
        font-size: 12px;
        line-height: unset;

        padding: 5px 0;
      }
    }
  `,

  btnDetail: {
    index: css`
      user-select: none;
    `,
    animate: css`
      display: flex;
      align-items: center;
      padding-top: 15px;
      position: relative;
      justify-content: space-between;
      cursor: pointer;

      ${media.md} {
        padding-top: 7px;
        padding-bottom: 3px;
        span {
          font-size: 12px !important;
          text-decoration: underline;
        }
      }

      &:hover {
        .arrow {
          span {
            border-color: ${theme.colors.secondary[900]};
            animation: unset;
          }
        }
      }
      span {
        font-size: 14px;
        line-height: 10px;
        position: relative;
        height: 100%;
        color: ${theme.colors.secondary[900]};
      }
      .arrow {
        margin-right: 25px;
        transform: rotate(-90deg);
        cursor: pointer;
      }
      .arrow span {
        display: block;
        width: 10px;
        height: 10px;
        border-bottom: 2px solid white;
        border-right: 2px solid white;
        transform: rotate(45deg);
        animation: ${animateArrow} 2s infinite;
      }
      .arrow span:nth-of-type(2) {
        animation-delay: -0.1s;
      }

      .arrow span:nth-of-type(3) {
        animation-delay: -0.2s;
      }
    `
  },
  modal: css`
    user-select: none;
    .ant-modal {
      &-content {
        background-color: #1d2841;
        border: 1.5px solid ${theme.colors.primary[300]};
      }
      &-close {
        color: white;
        span {
          font-size: 18px;
        }
      }
    }
  `,
  bonusModal: css`
    text-align: center;
    color: ${theme.colors.secondary[900]};
    font-size: 16px;
    font-weight: bold;
    padding-bottom: 5px;

    border-bottom: 1px solid ${theme.colors.neutrals[400]};
  `,
  contentModal: css`
    margin-top: 10px;
    color: white !important ;
    span {
      color: white !important ;
    }
    p {
      font {
        color: white !important ;
      }
    }
  `
};
