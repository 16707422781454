import SVG from "assets/icons/provider/SVG.png";
import SVG1 from "assets/icons/provider/SVG-1.png";
import SVG2 from "assets/icons/provider/SVG-2.png";
import SVG3 from "assets/icons/provider/SVG-3.png";
import SVG4 from "assets/icons/provider/SVG-4.png";
import SVG5 from "assets/icons/provider/SVG-5.png";
import SVG6 from "assets/icons/provider/SVG-6.png";
import SVG7 from "assets/icons/provider/SVG-7.png";
import SVG8 from "assets/icons/provider/SVG-8.png";
import SVG9 from "assets/icons/provider/SVG-9.png";
import SVG10 from "assets/icons/provider/SVG-10.png";
import SVG12 from "assets/icons/provider/SVG-12.png";
import SVG13 from "assets/icons/provider/SVG-13.png";
import SVG14 from "assets/icons/provider/SVG-14.png";
import SVG15 from "assets/icons/provider/SVG-15.png";
import SVG16 from "assets/icons/provider/SVG-16.png";

export const arrayProvider = [
  {
    link: "",
    icon: SVG
  },
  {
    link: "",
    icon: SVG1
  },
  {
    link: "",
    icon: SVG2
  },
  {
    link: "",
    icon: SVG3
  },
  {
    link: "",
    icon: SVG4
  },
  {
    link: "",
    icon: SVG5
  },
  {
    link: "",
    icon: SVG6
  },
  {
    link: "",
    icon: SVG7
  },
  {
    link: "",
    icon: SVG8
  },
  {
    link: "",
    icon: SVG9
  },
  {
    link: "",
    icon: SVG10
  },
  {
    link: "",
    icon: SVG12
  },
  {
    link: "",
    icon: SVG13
  },
  {
    link: "",
    icon: SVG14
  },
  {
    link: "",
    icon: SVG15
  },
  {
    link: "",
    icon: SVG16
  }
];
