import { css } from "@emotion/react";

export const CurrencyItemProfileStyle = {
  currencyItem: {
    index: css`
      padding: 15px;
      background: rgba(49, 60, 116, 0.5);
      border-radius: 10px;
    `,
    header: css`
      display: flex;
      align-items: center;
      gap: 10px;

      span {
        color: #fff;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 22px;
        text-transform: uppercase;
      }

      img {
        max-width: 30px;
      }
    `,
    bottom: css``
  }
};
