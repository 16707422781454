import ImageVip_1 from "assets/images/profile/vip-1.png";
import ImageVip_2 from "assets/images/profile/vip-2.png";
import ImageVip_3 from "assets/images/profile/vip-3.png";
import ImageVip_4 from "assets/images/profile/vip-4.png";
import ImageVip_5 from "assets/images/profile/vip-5.png";
import ImageVip_6 from "assets/images/profile/vip-6.png";
import ImageVip_7 from "assets/images/profile/vip-7.png";

interface TypeListMyVip {
  image: string;
  title: string;
  level: string;
  key: number;
}

export const LIST_MY_VIP: TypeListMyVip[] = [
  {
    image: ImageVip_1,
    title: "Vip1",
    level: "Newbie",
    key: 0
  },
  {
    image: ImageVip_2,
    title: "Vip2",
    level: "Junior",
    key: 1
  },
  {
    image: ImageVip_3,
    title: "Vip3",
    level: "Explorer",
    key: 2
  },
  {
    image: ImageVip_4,
    title: "Vip4",
    level: "Senior",
    key: 3
  },
  {
    image: ImageVip_5,
    title: "Vip5",
    level: "Respect",
    key: 4
  },
  {
    image: ImageVip_6,
    title: "Vip6",
    level: "Master",
    key: 5
  },
  {
    image: ImageVip_7,
    title: "Vip7",
    level: "Guru",
    key: 6
  }
];
