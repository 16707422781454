/** @jsxImportSource @emotion/react */

import ImageContentBg from "assets/images/promotion/promotion-detail-content-bg.png";
import HelmetCustom from "components/ui/Helmet";
import Loading from "components/ui/Loading";
import {
  LIST_CONTENT_TEXTBOX,
  LIST_INFO_TITLE
} from "dummy/list-promotion-detail";
import { useVerify } from "hooks/useVerify";

import { PromotionDetailStyle } from "./style.index";

const PromotionDetailPage = () => {
  const { isLoading } = useVerify();
  if (isLoading) return <Loading height={500} width={"100%"} center />;
  return (
    <div css={PromotionDetailStyle.wrapper}>
      <HelmetCustom title="Promotion Detail" />
      {LIST_INFO_TITLE.map((itemTitle) => (
        <div key={itemTitle.key} css={PromotionDetailStyle.title}>
          <img src={itemTitle.image} alt="" />
        </div>
      ))}

      <div css={PromotionDetailStyle.bottom.index}>
        {LIST_CONTENT_TEXTBOX.map((itemContent) => (
          <div key={itemContent.key} css={PromotionDetailStyle.bottom.textBox}>
            <img src={itemContent.image} alt="" />
            <div css={PromotionDetailStyle.bottom.content}>
              <p>{itemContent.title}</p>
              <span>{itemContent.number}</span>
              <p>{itemContent.describe}</p>
            </div>
          </div>
        ))}
        <div css={PromotionDetailStyle.describe.index}>
          <img src={ImageContentBg} alt="" />
          <div css={PromotionDetailStyle.describe.content}>
            <div css={PromotionDetailStyle.describe.title}>终生首充</div>
            <div css={PromotionDetailStyle.describe.paragraph}>
              <div className="break-words" data-v-51ca3d32="">
                <p>Bônus de primeiro depósito</p>
                <p>Detalhes do evento:</p>
                <p>Público-alvo：Todos os novos membros registrados do Feliz</p>
                <p>Horários dos eventos：A partir de 3 de junho de 2023</p>
                <p>Atividades：</p>
                <p>
                  Os membros recém-registrados podem reivindicar 100% do seu
                  primeiro depósito após uma primeira recarga bem-sucedida de
                  100 BRL, que pode ser reivindicada dentro de 30 minutos após a
                  não realização da aposta.
                </p>
                <p>&nbsp;</p>
                <p>1. primeiro depósito ≥ 100BRL</p>
                <p>2、Reclamar imediatamente após uma recarga bem-sucedida</p>
                <p>
                  3. para receber 100% de seus ganhos, você precisa completar 20
                  vezes o fluxo de águaválido antes de poder sacar seus ganhos
                </p>
                <p>4. o limite máximo para receber o bônus é de 1000 BRL</p>
                <p>&nbsp;</p>
                <p>
                  Exemplo: Você deposita 10 BRL pela primeira vez, pode
                  reivindicar 100% de bônus de 100BRL, precisa completar 20
                  vezes o valor da aposta válida antes de poder sacar, ou seja:
                  (100+100)*20=4000, então você pode sacar o dinheiro.
                </p>
                <p>&nbsp;</p>
                <p>&nbsp;</p>
                <p>Regras e termos do evento:</p>
                <p>
                  1. Esse bônus de primeiro depósito só pode ser apostado em
                  jogos eletrônicos ou jogos de pesca;
                </p>
                <p>
                  2. O mesmo membro, o mesmo IP, a mesma carteira, o mesmo
                  endereço de e-mail e o mesmo número de telefone só podem ser
                  aplicados uma vez;
                </p>
                <p>
                  3. as contas de jogo serão banidas se a mesma pessoa usar
                  contas diferentes ou registrar várias contas para receber o
                  bônus;
                </p>
                <p>
                  4. qualquer usuário ou equipe que aposte de maneira imprópria,
                  como apostas sem risco, apostas combinadas, arbitragem ou
                  trapaça para obter lucro, Feliz se reserva o direito de
                  congelar ou fechar a conta relevante sem aviso prévio;
                </p>
                <p>
                  5. para evitar diferenças textuais de entendimento, Feliz se
                  reserva o direito de interpretação final do evento e o direito
                  de modificar o término do evento sem aviso prévio.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PromotionDetailPage;
