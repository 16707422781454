/** @jsxImportSource @emotion/react */

import { useAppSelector } from "hooks/app-hook";
import { useEffect } from "react";
import { Outlet, useNavigate, useOutletContext } from "react-router-dom";
import { outletContextType } from "types";

const CashLineRoute = () => {
  const { profile } = useAppSelector((state) => state.user);
  const { openNotification } = useOutletContext<outletContextType>();
  const navigate = useNavigate();

  useEffect(() => {
    if (profile && profile.upline !== "cashline") {
      navigate("/");
    }
  }, [profile]);

  return <Outlet context={{ openNotification } as outletContextType} />;
};
export default CashLineRoute;
