import { css } from "@emotion/react";
import { media } from "styles/breakpoints";
import { theme } from "styles/theme";

export const PercentagePageStyle = {
  wrapper: css``,
  content: css`
    margin-top: 20px;
    padding: 10px 22px;

    display: flex;
    align-items: center;
    justify-content: space-between;
    user-select: none;

    ${media.lg} {
      display: grid;
      grid-template-columns: repeat(4, 1fr);
      gap: 20px;
    }
    ${media.md} {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
    }
    ${media.sm} {
      display: grid;
      grid-template-columns: 1fr 1fr;
    }
  `,
  item: {
    index: css`
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: 8px;

      &:hover {
        span {
          border-radius: unset;
          transition: 0.25s;
          color: ${theme.colors.secondary[900]};
        }
        .hover-effect {
          border-color: ${theme.colors.secondary[900]};
          border-radius: 12px;
          transition: 0.25s;
        }
      }
    `,
    title: css`
      border: 1px solid ${theme.colors.secondary[900]};
      border-radius: 18px;
      padding: 5px 10px;
      font-size: 14px;

      transition: 0.25s;
      ${media.sm} {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    `,
    percent: css`
      border: 1px solid #3d4668cc;
      border-bottom: none;
      padding: 5px 10px;
      width: 100%;

      text-align: center;
      font-size: 12px;
      transition: 0.25s;
    `
  }
};
